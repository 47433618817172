@use 'base/breakpoints' as *;
@use 'base/functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.fade {
  transition: opacity 0.15s linear;
  &:not(.show) {
    opacity: 0;
  }
}
.collapse {
  &:not(.show) {
    display: none;
  }
}
.collapsing {
  height: 0;
  overflow: hidden;
  position: relative;
  transition: height 0.35s ease;
}
.collapsing.width {
  height: auto;
  transition: width 0.35s ease;
  width: 0;
}
.pgn-transition-replace-group {
  text-align: start;
  transition: height 0.3s ease;
}
.pgn__transition-replace-enter {
  opacity: 0;
}
.pgn__transition-replace-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease;
  z-index: 1;
}
.pgn__transition-replace-exit {
  opacity: 1;
}
.pgn__transition-replace-exit-active {
  opacity: 0;
  transition: opacity 0.3s ease;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
  .collapsing {
    transition: none;
  }
  .collapsing.width {
    transition: none;
  }
}

// Loading ellipsis
.loading {
  font-size: 13px;
  padding-left: 10px;
}

.loading:after {
  animation: ellipsis 0.9s steps(4) infinite;
  content: "…";
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
  width: 0;
}


@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color: var(--base-color);

  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  user-select: none;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

.react-loading-skeleton::after {
  content: " ";
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    var(--base-color),
    var(--highlight-color),
    var(--base-color)
  );
  transform: translateX(-100%);

  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion) {
  .react-loading-skeleton {
    --pseudo-element-display: none; /* Disable animation */
  }
}


.react-loading-skeleton {
  z-index: unset;
}


@keyframes progress-bar-stripes {
  0% {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  font-size: 0.84375rem;
  height: 1rem;
  line-height: 0;
}
.progress,
.progress-bar {
  display: flex;
  overflow: hidden;
}
.progress-bar {
  color: var(--mono-100);
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: width 0.6s ease;
  white-space: nowrap;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-size: 1rem 1rem;
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0) 50%,
    rgba(255, 255, 255, 0.15) 0,
    rgba(255, 255, 255, 0.15) 75%,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0)
  );
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.progress {
  border: 1px solid #707070;
}
.progress-bar {
  background-color: #00bbf9;
}


.spinner-border {
  animation: spinner-border 0.75s linear infinite;
  border: 0.25em solid;
  border-radius: 50%;
  border-right-color: rgba(0, 0, 0, 0);
  display: inline-block;
  height: 2rem;
  vertical-align: -0.125em;
  width: 2rem;
}

.spinner-grow-sm {
  height: 1rem;
  width: 1rem;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}

.icon-spin {
  animation: spinner-border 0.75s linear infinite;
  -webkit-animation: spinner-border 0.75s linear infinite;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  height: 0;
  overflow: hidden;
  position: relative;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  height: auto;
  transition: width 0.35s ease;
  width: 0;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}
