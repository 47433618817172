@use 'base/breakpoints' as *;
@use 'base/functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: var(--mono-900);
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0.5rem;
}
.h1 {
  font-size: 2.5rem;
}
.h2 {
  font-size: 2rem;
}
.h3 {
  font-size: 1.375rem;
}
.h4 {
  font-size: 1.125rem;
}
.h5 {
  font-size: 0.875rem;
}
.h6 {
  font-size: 0.75rem;
}
.lead {
  font-size: 1.40625rem;
}