@use 'base/breakpoints' as *;
@use 'base/functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

// TODO: some of these are already duplicated in the base/buttonish partial - we should combine them or isolate them appropriately (are there ones we use only in the MFEs? They should go here. If there are ones used sitewide across the static site, the theme, and MFEs? Put it in buttonish.
.btn {
  /*
  - Learning: "Launch Tour" button/link
  */
  &-link {
    background: none;
    text-decoration: underline;
    color: var(--mono, 100);
    border: transparent;
    border-radius: 0;
    padding: 0;

    &:hover,
    &:active,
    &.active {
      background-color: transparent;
      color: inherit;
      text-decoration: none;
    }
  }

  /*
  - Learner Dashboard: For the "Refine" button
  - Learning: "Expand All" button
  */

  // &-outline-primary,
  // &-brand,
  // &-tertiary {
  //   padding: 0.5rem 1rem;
  // }

  &-outline-primary {
    color: var(--primary-500);
    border-color: var(--primary-500);
    background-color: transparent;
    // display: initial;
  }

  // &-brand {
  //   padding: 0.5rem 1rem;
  // }

  &-tertiary {
    background: var(--mono-700);
    border-color: var(--mono-700);
    // padding: 0.5rem 1rem;
  }
}

// Close button mess copied from authn
.close {
  color: var(--mono-900);
  font-size: 1.6875rem;
  font-weight: 700;
  line-height: 1;
  opacity: 0.5;
  text-shadow: 0 1px 0 var(--mono-100);
  float: right;

  &:hover {
    color: var(--mono-900);
    text-decoration: none;
  }

  &:not(:disabled) {
    &:not(.disabled) {
      &:focus {
        opacity: 0.75;
      }
      &:hover {
        opacity: 0.75;
      }
    }
  }
}

button.close {
  background-color: transparent;
  border: 0;
  padding: 0;
}
a.close.disabled {
  pointer-events: none;
}
