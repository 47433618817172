@use 'base/breakpoints' as *;
@use 'functions' as *;

select {
  color: var(--mono-900);
}

// Copied from current site
input,
select,
textarea {
  display: block;
  height: auto;
  width: auto;
  margin: 0;
  padding: 1rem;
  border: rem-calc(1) solid var(--mono-300);
  font-size: 1rem;
}
