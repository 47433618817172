@use 'base/breakpoints' as *;
@use 'base/functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

$color_1: var(--mono-100);
$color_2: var(--mono-900);
$font-family_1: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
  Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
$background-color_1: var(--mono-900);
$background-color_2: var(--mono-100);
$border-color_1: var(--mono-1000);
$border-top-color_1: var(--mono-900);
$border-top-color_2: var(--mono-100);
$border-right-color_1: var(--mono-900);
$border-right-color_2: var(--mono-100);
$border-bottom-color_1: var(--mono-900);
$border-bottom-color_2: var(--mono-100);
$border-left-color_1: var(--mono-900);
$border-left-color_2: var(--mono-100);

.tooltip {
  word-wrap: break-word;
  display: block;
  font-family: $font-family_1;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5556;
  margin: 0;
  opacity: 0;
  position: absolute;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: 1070;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.15))
    drop-shadow(0 2px 8px rgba(0, 0, 0, 0.15));
  .arrow {
    display: block;
    height: 0.4rem;
    position: absolute;
    width: 0.8rem;
    &:before {
      border-color: $border-color_1;
      border-style: solid;
      content: "";
      position: absolute;
    }
  }
}
.tooltip.show {
  opacity: 1;
}
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
  .arrow {
    bottom: 0;
    &:before {
      border-top-color: $border-top-color_1;
      border-width: 0.4rem 0.4rem 0;
      top: 0;
    }
  }
}
.bs-tooltip-top {
  padding: 0.4rem 0;
  .arrow {
    bottom: 0;
    &:before {
      border-top-color: $border-top-color_1;
      border-width: 0.4rem 0.4rem 0;
      top: 0;
    }
  }
}
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
  .arrow {
    height: 0.8rem;
    width: 0.4rem;
    left: 0;
    &:before {
      border-right-color: $border-right-color_1;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      right: 0;
    }
  }
}
.bs-tooltip-right {
  padding: 0 0.4rem;
  .arrow {
    height: 0.8rem;
    width: 0.4rem;
    left: 0;
    &:before {
      border-right-color: $border-right-color_1;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      right: 0;
    }
  }
}
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
  .arrow {
    top: 0;
    &:before {
      border-bottom-color: $border-bottom-color_1;
      border-width: 0 0.4rem 0.4rem;
      bottom: 0;
    }
  }
}
.bs-tooltip-bottom {
  padding: 0.4rem 0;
  .arrow {
    top: 0;
    &:before {
      border-bottom-color: $border-bottom-color_1;
      border-width: 0 0.4rem 0.4rem;
      bottom: 0;
    }
  }
}
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
  .arrow {
    height: 0.8rem;
    width: 0.4rem;
    right: 0;
    &:before {
      border-left-color: $border-left-color_1;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      left: 0;
    }
  }
}
.bs-tooltip-left {
  padding: 0 0.4rem;
  .arrow {
    height: 0.8rem;
    width: 0.4rem;
    right: 0;
    &:before {
      border-left-color: $border-left-color_1;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      left: 0;
    }
  }
}
.tooltip-inner {
  background-color: $background-color_1;
  border-radius: 0.375rem;
  color: $color_1;
  max-width: 200px;
  padding: 0.5rem;
  text-align: center;
}
.tooltip-light {
  .tooltip-inner {
    background-color: $background-color_2;
    color: $color_2;
  }
}
.tooltip-light.bs-tooltip-auto[x-placement^="top"] {
  .arrow {
    &:before {
      border-top-color: $border-top-color_2;
    }
  }
}
.tooltip-light.bs-tooltip-top {
  .arrow {
    &:before {
      border-top-color: $border-top-color_2;
    }
  }
}
.tooltip-light.bs-tooltip-auto[x-placement^="right"] {
  .arrow {
    &:before {
      border-right-color: $border-right-color_2;
    }
  }
}
.tooltip-light.bs-tooltip-right {
  .arrow {
    &:before {
      border-right-color: $border-right-color_2;
    }
  }
}
.tooltip-light.bs-tooltip-auto[x-placement^="bottom"] {
  .arrow {
    &:before {
      border-bottom-color: $border-bottom-color_2;
    }
  }
}
.tooltip-light.bs-tooltip-bottom {
  .arrow {
    &:before {
      border-bottom-color: $border-bottom-color_2;
    }
  }
}
.tooltip-light.bs-tooltip-auto[x-placement^="left"] {
  .arrow {
    &:before {
      border-left-color: $border-left-color_2;
    }
  }
}
.tooltip-light.bs-tooltip-left {
  .arrow {
    &:before {
      border-left-color: $border-left-color_2;
    }
  }
}
