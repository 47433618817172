// Screen reader support {
.sr,
.sr-only,
.visually-hidden,
.nav-skip {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);

  &-focusable:active,
  &-focusable:focus {
    position: static;
    white-space: inherit;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}


.hide,
.hidden,
[hidden] {
  display: none !important;
}


// Fadeout animation

.fadeout {
  animation: fadeout 0.125s ease-out forwards;
}

@keyframes fadeout {

  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }

}
