@use 'base/breakpoints' as *;
@use 'base/functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

$color_1: #454545;
$color_2: #707070;
$color_3: var(--mono-1000);
$color_4: var(--green-600);
$color_5: var(--mono-100);
$color_6: var(--red-600);
$color_7: var(--primary-500);
$color_8: #212529;
$color_9: #005788;
$color_10: #006daa;
$color_11: #082644;
$color_12: #666;
$background-color_1: var(--mono-100);
$background-color_2: var(--mono-1000);
$background-color_3: #ebebeb;
$background-color_4: #178253e6;
$background-color_5: #1fad6f;
$background-color_6: #c32d3ae6;
$background-color_7: #d64d59;
$background-color_8: var(--primary-500);
$background-color_9: var(--primary-500); // transparent-ish primary color?
$background-color_10: #454545;
$background-color_11: #2b8ae6;
$background-color_12: #adadad;
$background-color_13: #707070;
$background-color_14: rgba(255, 255, 255, 0.01);
$background-color_15: var(--green-600);
$border-color_1: var(--primary-500);
$border-color_2: var(--green-600);
$border-color_3: #1fad6f;
$border-color_4: var(--red-600);
$border-color_5: #d64d59;
$border-color_6: var(--mono-1000);
$border-color_7: #707070;
$border-color_8: var(--mono-900);
$border-top-color_1: var(--mono-100);
$border-right-color_1: var(--mono-1000);
$border-left-color_1: var(--mono-100);

.form-control {
  background-clip: padding-box;
  background-color: $background-color_1;
  border: 1px solid #707070;
  border-bottom: 2px solid #ff5f14;
  border-radius: 0;
  color: $color_1;
  display: block;
  font-size: rem-calc(16);
  font-weight: 400;
  margin: 0;
  height: rem-calc(44);
  line-height: 1.3333;
  padding: 0.5625rem 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  &::-ms-expand {
    background-color: $background-color_2;
    border: 0;
  }
  &:focus {
    background-color: $background-color_1;
    border-color: $border-color_1;
    box-shadow: 0 0 0 1px var(--primary-500);
    color: $color_1;
    outline: 0;
    ~ {
      .pgn__form-control-floating-label {
        .pgn__form-control-floating-label-text {
          background-color: $background-color_1;
          transform: translateY(-0.5625rem) scale(0.75) translateY(-80.0007500188%);
        }
      }
    }
  }
  &::placeholder {
    color: $color_2;
    opacity: 1;
    font-size: rem-calc(14);
  }
  &:disabled {
    background-color: $background-color_3;
    opacity: 1;
  }
  + {
    .pgn__form-text {
      margin-top: 0.25rem;
    }
  }
  &:hover {
    border: 1px solid #1f3226;
  }
}
.form-control[readonly] {
  background-color: $background-color_3;
  opacity: 1;
}
input[type="date"].form-control {
  -webkit-appearance: none;
  appearance: none;
}
input[type="datetime-local"].form-control {
  -webkit-appearance: none;
  appearance: none;
}
input[type="month"].form-control {
  -webkit-appearance: none;
  appearance: none;
}
input[type="time"].form-control {
  -webkit-appearance: none;
  appearance: none;
}
select.form-control {
  &:-moz-focusring {
    color: $color_3;
    text-shadow: 0 0 0 #454545;
  }
  &:focus {
    &::-ms-value {
      background-color: $background-color_1;
      color: $color_1;
    }
  }
  -webkit-appearance: none;
  appearance: none;
  // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23454545' d='M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  padding-inline-end: 2.5625rem;
  background-position: right 0.5625rem center;
}
.form-control-file {
  display: block;
  width: 100%;
}
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  font-size: inherit;
  line-height: 1.3333;
  margin-bottom: 0;
  padding-bottom: calc(0.5625rem + 1px);
  padding-top: calc(0.5625rem + 1px);
}
.col-form-label-lg {
  font-size: 1.325rem;
  line-height: 1.5;
  padding-bottom: calc(0.6875rem + 1px);
  padding-top: calc(0.6875rem + 1px);
}
.col-form-label-sm {
  font-size: 0.875rem;
  line-height: 1.4286;
  padding-bottom: calc(0.4375rem + 1px);
  padding-top: calc(0.4375rem + 1px);
}
.form-control-plaintext {
  background-color: $background-color_2;
  border: solid var(--mono-1000);
  border-width: 1px 0;
  color: $color_1;
  display: block;
  font-size: 1.125rem;
  line-height: 1.3333;
  margin-bottom: 0;
  padding: 0.5625rem 0;
  width: 100%;
}
.form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}
.form-control-plaintext.form-control-sm {
  padding-left: 0;
  padding-right: 0;
}
.form-control-sm {
  border-radius: 0.25rem;
  font-size: 0.875rem;
  height: calc(1.4286em + 0.875rem + 2px);
  line-height: 1.4286;
  padding: 0.4375rem 0.75rem;
}
.form-control-lg {
  border-radius: 0.425rem;
  font-size: 1.325rem;
  height: calc(1.5em + 1.375rem + 2px);
  line-height: 1.5;
  padding: 0.6875rem 1.25rem;
}
select.form-control[multiple] {
  height: auto;
}
select.form-control[size] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  >.col {
    padding-left: 5px;
    padding-right: 5px;
    >.valid-tooltip {
      left: 5px;
    }
    >.invalid-tooltip {
      left: 5px;
    }
  }
  >[class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
    >.valid-tooltip {
      left: 5px;
    }
    >.invalid-tooltip {
      left: 5px;
    }
  }
}
.form-check {
  display: block;
  position: relative;
  padding-left: 1.25rem;
}
.form-check-input {
  margin-top: 0.3rem;
  position: absolute;
  margin-left: -1.25rem;
  &:disabled {
    ~ {
      .form-check-label {
        color: $color_2;
      }
    }
  }
}
.form-check-input[disabled] {
  ~ {
    .form-check-label {
      color: $color_2;
    }
  }
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  align-items: center;
  display: inline-flex;
  margin-right: 0.75rem;
  padding-left: 0;
  .form-check-input {
    margin-top: 0;
    position: static;
    margin-left: 0;
    margin-right: 0.3125rem;
  }
}
.valid-feedback {
  color: $color_4;
  display: none;
  font-size: 87.5%;
  margin-top: 0.25rem;
  width: 100%;
}
.valid-tooltip {
  background-color: $background-color_4;
  border-radius: 0.375rem;
  color: $color_5;
  display: none;
  font-size: 0.875rem;
  line-height: 1.5556;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
  left: 0;
}
.is-valid {
  ~ {
    .valid-feedback {
      display: block;
    }
    .valid-tooltip {
      display: block;
    }
  }
}
.was-validated {
  &:valid {
    ~ {
      .valid-feedback {
        display: block;
      }
      .valid-tooltip {
        display: block;
      }
    }
  }
  .form-control {
    &:valid {
      border-color: $border-color_2;
      &:focus {
        border-color: $border-color_2;
        box-shadow: 0 0 0 1px #17825340;
      }
    }
    &:invalid {
      border-color: $border-color_4;
      &:focus {
        border-color: $border-color_4;
        box-shadow: 0 0 0 1px #c32d3a40;
      }
    }
  }
  .custom-select {
    &:valid {
      border-color: $border-color_2;
      &:focus {
        border-color: $border-color_2;
        box-shadow: 0 0 0 1px #17825340;
      }
    }
    &:invalid {
      border-color: $border-color_4;
      &:focus {
        border-color: $border-color_4;
        box-shadow: 0 0 0 1px #c32d3a40;
      }
    }
  }
  .form-check-input {
    &:valid {
      ~ {
        .form-check-label {
          color: $color_4;
        }
        .valid-feedback {
          display: block;
        }
        .valid-tooltip {
          display: block;
        }
      }
    }
    &:invalid {
      ~ {
        .form-check-label {
          color: $color_6;
        }
        .invalid-feedback {
          display: block;
        }
        .invalid-tooltip {
          display: block;
        }
      }
    }
  }
  .custom-control-input {
    &:valid {
      ~ {
        .custom-control-label {
          color: $color_4;
          &::before {
            border-color: $border-color_2;
          }
        }
      }
      &:checked {
        ~ {
          .custom-control-label {
            &::before {
              background-color: $background-color_5;
              border-color: $border-color_3;
            }
          }
        }
      }
      &:focus {
        ~ {
          .custom-control-label {
            &::before {
              box-shadow: 0 0 0 1px #17825340;
            }
          }
        }
        &:not(:checked) {
          ~ {
            .custom-control-label {
              &::before {
                border-color: $border-color_2;
              }
            }
          }
        }
      }
    }
    &:invalid {
      ~ {
        .custom-control-label {
          color: $color_6;
          &::before {
            border-color: $border-color_4;
          }
        }
      }
      &:checked {
        ~ {
          .custom-control-label {
            &::before {
              background-color: $background-color_7;
              border-color: $border-color_5;
            }
          }
        }
      }
      &:focus {
        ~ {
          .custom-control-label {
            &::before {
              box-shadow: 0 0 0 1px #c32d3a40;
            }
          }
        }
        &:not(:checked) {
          ~ {
            .custom-control-label {
              &::before {
                border-color: $border-color_4;
              }
            }
          }
        }
      }
    }
  }
  .custom-file-input {
    &:valid {
      ~ {
        .custom-file-label {
          border-color: $border-color_2;
        }
      }
      &:focus {
        ~ {
          .custom-file-label {
            border-color: $border-color_2;
            box-shadow: 0 0 0 1px #17825340;
          }
        }
      }
    }
    &:invalid {
      ~ {
        .custom-file-label {
          border-color: $border-color_4;
        }
      }
      &:focus {
        ~ {
          .custom-file-label {
            border-color: $border-color_4;
            box-shadow: 0 0 0 1px #c32d3a40;
          }
        }
      }
    }
  }
  &:invalid {
    ~ {
      .invalid-feedback {
        display: block;
      }
      .invalid-tooltip {
        display: block;
      }
    }
  }
}
.form-control.is-valid {
  border-color: $border-color_2;
  &:focus {
    border-color: $border-color_2;
    box-shadow: 0 0 0 1px #17825340;
  }
}
.custom-select.is-valid {
  border-color: $border-color_2;
  &:focus {
    border-color: $border-color_2;
    box-shadow: 0 0 0 1px #17825340;
  }
}
.form-check-input.is-valid {
  ~ {
    .form-check-label {
      color: $color_4;
    }
    .valid-feedback {
      display: block;
    }
    .valid-tooltip {
      display: block;
    }
  }
}
.custom-control-input.is-valid {
  ~ {
    .custom-control-label {
      color: $color_4;
      &::before {
        border-color: $border-color_2;
      }
    }
  }
  &:checked {
    ~ {
      .custom-control-label {
        &::before {
          background-color: $background-color_5;
          border-color: $border-color_3;
        }
      }
    }
  }
  &:focus {
    ~ {
      .custom-control-label {
        &::before {
          box-shadow: 0 0 0 1px #17825340;
        }
      }
    }
    &:not(:checked) {
      ~ {
        .custom-control-label {
          &::before {
            border-color: $border-color_2;
          }
        }
      }
    }
  }
}
.custom-file-input.is-valid {
  ~ {
    .custom-file-label {
      border-color: $border-color_2;
    }
  }
  &:focus {
    ~ {
      .custom-file-label {
        border-color: $border-color_2;
        box-shadow: 0 0 0 1px #17825340;
      }
    }
  }
}
.invalid-feedback {
  display: none;
  font-size: 87.5%;
  margin-top: 0.25rem;
  width: 100%;
}
.invalid-tooltip {
  background-color: $background-color_6;
  border-radius: 0.375rem;
  color: $color_5;
  display: none;
  font-size: 0.875rem;
  line-height: 1.5556;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
  left: 0;
}
.is-invalid {
  ~ {
    .invalid-feedback {
      display: block;
    }
    .invalid-tooltip {
      display: block;
    }
  }
}
.form-control.is-invalid {
  border-color: $border-color_4;
  &:focus {
    border-color: $border-color_4;
    box-shadow: 0 0 0 1px #c32d3a40;
  }
  ~ {
    .invalid-feedback-nodanger {
      color: $color_1;
    }
  }
}
.custom-select.is-invalid {
  border-color: $border-color_4;
  &:focus {
    border-color: $border-color_4;
    box-shadow: 0 0 0 1px #c32d3a40;
  }
}
.form-check-input.is-invalid {
  ~ {
    .form-check-label {
      color: $color_6;
    }
    .invalid-feedback {
      display: block;
    }
    .invalid-tooltip {
      display: block;
    }
  }
}
.custom-control-input.is-invalid {
  ~ {
    .custom-control-label {
      color: $color_6;
      &::before {
        border-color: $border-color_4;
      }
    }
  }
  &:checked {
    ~ {
      .custom-control-label {
        &::before {
          background-color: $background-color_7;
          border-color: $border-color_5;
        }
      }
    }
  }
  &:focus {
    ~ {
      .custom-control-label {
        &::before {
          box-shadow: 0 0 0 1px #c32d3a40;
        }
      }
    }
    &:not(:checked) {
      ~ {
        .custom-control-label {
          &::before {
            border-color: $border-color_4;
          }
        }
      }
    }
  }
}
.custom-file-input.is-invalid {
  ~ {
    .custom-file-label {
      border-color: $border-color_4;
    }
  }
  &:focus {
    ~ {
      .custom-file-label {
        border-color: $border-color_4;
        box-shadow: 0 0 0 1px #c32d3a40;
      }
    }
  }
}
.form-inline {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  .form-check {
    width: 100%;
  }
}
.input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  >.custom-file {
    flex: 1 1 auto;
    margin-bottom: 0;
    min-width: 0;
    position: relative;
    width: 1%;
    align-items: center;
    display: flex;
    + {
      .custom-file {
        margin-left: -1px;
      }
      .custom-select {
        margin-left: -1px;
      }
      .form-control {
        margin-left: -1px;
      }
    }
    .custom-file-input {
      &:focus {
        ~ {
          .custom-file-label {
            z-index: 3;
          }
        }
        z-index: 4;
      }
    }
    &:not(:last-child) {
      .custom-file-label {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        &::after {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
  }
  >.custom-select {
    flex: 1 1 auto;
    margin-bottom: 0;
    min-width: 0;
    position: relative;
    width: 1%;
    + {
      .custom-file {
        margin-left: -1px;
      }
      .custom-select {
        margin-left: -1px;
      }
      .form-control {
        margin-left: -1px;
      }
    }
    &:focus {
      z-index: 3;
    }
    &:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
  >.form-control {
    flex: 1 1 auto;
    margin-bottom: 0;
    min-width: 0;
    position: relative;
    width: 1%;
    + {
      .custom-file {
        margin-left: -1px;
      }
      .custom-select {
        margin-left: -1px;
      }
      .form-control {
        margin-left: -1px;
      }
    }
    &:focus {
      z-index: 3;
    }
    &:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
  >.form-control-plaintext {
    flex: 1 1 auto;
    margin-bottom: 0;
    min-width: 0;
    position: relative;
    width: 1%;
    + {
      .custom-file {
        margin-left: -1px;
      }
      .custom-select {
        margin-left: -1px;
      }
      .form-control {
        margin-left: -1px;
      }
    }
  }
  &:not(.has-validation) {
    >.custom-file {
      &:not(:last-child) {
        .custom-file-label {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          &::after {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }
    >.custom-select {
      &:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
    >.form-control {
      &:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
    >.input-group-append {
      &:not(:last-child) {
        >.btn {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
        >.input-group-text {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
  }
  >.input-group-append {
    &:last-child {
      >.btn {
        &:not(:last-child) {
          &:not(.dropdown-toggle) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
      >.input-group-text {
        &:not(:last-child) {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
    >.btn {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
    >.input-group-text {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
  >.input-group-prepend {
    >.btn {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    >.input-group-text {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    &:first-child {
      >.btn {
        &:not(:first-child) {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
      >.input-group-text {
        &:not(:first-child) {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }
    &:not(:first-child) {
      >.btn {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
      >.input-group-text {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
}
.input-group.has-validation {
  >.custom-file {
    &:nth-last-child(n + 3) {
      .custom-file-label {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        &::after {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
  }
  >.custom-select {
    &:nth-last-child(n + 3) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
  >.form-control {
    &:nth-last-child(n + 3) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
  >.input-group-append {
    &:nth-last-child(n + 3) {
      >.btn {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
      >.input-group-text {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}
.input-group-append {
  display: flex;
  margin-left: -1px;
  .btn {
    position: relative;
    z-index: 2;
    &:focus {
      z-index: 3;
    }
    + {
      .btn {
        margin-left: -1px;
      }
      .input-group-text {
        margin-left: -1px;
      }
    }
  }
  .input-group-text {
    + {
      .btn {
        margin-left: -1px;
      }
      .input-group-text {
        margin-left: -1px;
      }
    }
  }
}
.input-group-prepend {
  display: flex;
  margin-right: -1px;
  .btn {
    position: relative;
    z-index: 2;
    &:focus {
      z-index: 3;
    }
    + {
      .btn {
        margin-left: -1px;
      }
      .input-group-text {
        margin-left: -1px;
      }
    }
  }
  .input-group-text {
    + {
      .btn {
        margin-left: -1px;
      }
      .input-group-text {
        margin-left: -1px;
      }
    }
  }
}
.input-group-text {
  align-items: center;
  background-color: $background-color_3;
  border: 1px solid #707070;
  border-radius: 0.375rem;
  color: $color_1;
  display: flex;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3333;
  margin-bottom: 0;
  padding: 0.5625rem 1rem;
  text-align: center;
  white-space: nowrap;
  input[type="checkbox"] {
    margin-top: 0;
  }
  input[type="radio"] {
    margin-top: 0;
  }
}
.input-group-lg {
  >.custom-select {
    height: calc(1.5em + 1.375rem + 2px);
    border-radius: 0.425rem;
    font-size: 1.325rem;
    line-height: 1.5;
    padding: 0.6875rem 1.25rem;
    padding-right: 2rem;
  }
  >.form-control {
    &:not(textarea) {
      height: calc(1.5em + 1.375rem + 2px);
    }
    border-radius: 0.425rem;
    font-size: 1.325rem;
    line-height: 1.5;
    padding: 0.6875rem 1.25rem;
  }
  >.input-group-append {
    >.btn {
      border-radius: 0.425rem;
      font-size: 1.325rem;
      line-height: 1.5;
      padding: 0.6875rem 1.25rem;
    }
    >.input-group-text {
      border-radius: 0.425rem;
      font-size: 1.325rem;
      line-height: 1.5;
      padding: 0.6875rem 1.25rem;
    }
  }
  >.input-group-prepend {
    >.btn {
      border-radius: 0.425rem;
      font-size: 1.325rem;
      line-height: 1.5;
      padding: 0.6875rem 1.25rem;
    }
    >.input-group-text {
      border-radius: 0.425rem;
      font-size: 1.325rem;
      line-height: 1.5;
      padding: 0.6875rem 1.25rem;
    }
  }
}
.input-group-sm {
  >.custom-select {
    height: calc(1.4286em + 0.875rem + 2px);
    border-radius: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.4286;
    padding: 0.4375rem 0.75rem;
    padding-right: 2rem;
  }
  >.form-control {
    &:not(textarea) {
      height: calc(1.4286em + 0.875rem + 2px);
    }
    border-radius: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.4286;
    padding: 0.4375rem 0.75rem;
  }
  >.input-group-append {
    >.btn {
      border-radius: 0.25rem;
      font-size: 0.875rem;
      line-height: 1.4286;
      padding: 0.4375rem 0.75rem;
    }
    >.input-group-text {
      border-radius: 0.25rem;
      font-size: 0.875rem;
      line-height: 1.4286;
      padding: 0.4375rem 0.75rem;
    }
  }
  >.input-group-prepend {
    >.btn {
      border-radius: 0.25rem;
      font-size: 0.875rem;
      line-height: 1.4286;
      padding: 0.4375rem 0.75rem;
    }
    >.input-group-text {
      border-radius: 0.25rem;
      font-size: 0.875rem;
      line-height: 1.4286;
      padding: 0.4375rem 0.75rem;
    }
  }
}
.custom-control {
  display: block;
  min-height: 1.75005rem;
  position: relative;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  z-index: 1;
  padding-left: 1.75rem;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  height: 1.500025rem;
  opacity: 0;
  position: absolute;
  width: 1.25rem;
  z-index: -1;
  left: 0;
  &:checked {
    ~ {
      .custom-control-label {
        &::before {
          background-color: $background-color_8;
          border-color: $border-color_1;
          color: $color_7;
        }
      }
    }
  }
  &:focus {
    ~ {
      .custom-control-label {
        &::before {
          box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
        }
      }
    }
    &:not(:checked) {
      ~ {
        .custom-control-label {
          &::before {
            border-color: $border-color_1;
          }
        }
      }
    }
  }
  &:not(:disabled) {
    &:active {
      ~ {
        .custom-control-label {
          &::before {
            background-color: $background-color_8;
            border-color: $border-color_1;
            color: $color_5;
          }
        }
      }
    }
  }
  &:disabled {
    ~ {
      .custom-control-label {
        color: $color_2;
        &::before {
          background-color: $background-color_3;
        }
      }
    }
  }
}
.custom-control-input[disabled] {
  ~ {
    .custom-control-label {
      color: $color_2;
      &::before {
        background-color: $background-color_3;
      }
    }
  }
}
.custom-control-label {
  margin-bottom: 0;
  position: relative;
  vertical-align: top;
  &::before {
    background-color: $background-color_1;
    border: 2px solid #454545;
    content: "";
    display: block;
    height: 1.25rem;
    pointer-events: none;
    position: absolute;
    top: 0.250025rem;
    width: 1.25rem;
    left: -1.75rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  &::after {
    background: 50%/100% no-repeat;
    content: "";
    display: block;
    height: 1.25rem;
    position: absolute;
    top: 0.250025rem;
    width: 1.25rem;
    left: -1.75rem;
  }
}
.custom-checkbox {
  .custom-control-label {
    &::before {
      border-radius: 0;
    }
  }
  .custom-control-input {
    &:checked {
      ~ {
        .custom-control-label {
          &::after {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%230A3055' d='M21 3H3v18h18V3ZM10 17l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z'/%3E%3C/svg%3E");
          }
        }
      }
    }
    &:indeterminate {
      ~ {
        .custom-control-label {
          &::before {
            background-color: $background-color_8;
            border-color: $border-color_1;
          }
          &::after {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%230A3055' d='M21 3H3v18h18V3Zm-4 10H7v-2h10v2Z'/%3E%3C/svg%3E");
          }
        }
      }
    }
    &:disabled {
      &:checked {
        ~ {
          .custom-control-label {
            &::before {
              background-color: $background-color_9;
            }
          }
        }
      }
      &:indeterminate {
        ~ {
          .custom-control-label {
            &::before {
              background-color: $background-color_9;
            }
          }
        }
      }
    }
  }
}
.custom-radio {
  .custom-control-label {
    &::before {
      border-radius: 50%;
    }
  }
  .custom-control-input {
    &:checked {
      ~ {
        .custom-control-label {
          &::after {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%230A3055'/%3E%3C/svg%3E");
          }
        }
      }
    }
    &:disabled {
      &:checked {
        ~ {
          .custom-control-label {
            &::before {
              background-color: $background-color_9;
            }
          }
        }
      }
    }
  }
}
.custom-switch {
  padding-left: 2.6875rem;
  .custom-control-label {
    &::before {
      border-radius: 0.625rem;
      pointer-events: all;
      width: 2.1875rem;
      left: -2.6875rem;
    }
    &::after {
      background-color: $background-color_10;
      border-radius: 0.625rem;
      height: calc(1.25rem - 8px);
      top: calc(0.25003rem + 4px);
      transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      width: calc(1.25rem - 8px);
      left: calc(-2.6875rem + 4px);
    }
  }
  .custom-control-input {
    &:checked {
      ~ {
        .custom-control-label {
          &::after {
            background-color: $background-color_1;
            transform: translateX(0.9375rem);
          }
        }
      }
    }
    &:disabled {
      &:checked {
        ~ {
          .custom-control-label {
            &::before {
              background-color: $background-color_9;
            }
          }
        }
      }
    }
  }
}
.custom-select {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #707070;
  border-radius: 0.375rem;
  color: $color_1;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 400;
  height: calc(1.3333em + 1.125rem + 2px);
  line-height: 1.3333;
  vertical-align: middle;
  width: 100%;
  background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23454545' d='M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41Z'/%3E%3C/svg%3E") no-repeat right 0.5625rem center/24px 24px;
  padding: 0.5625rem 2rem 0.5625rem 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    border-color: $border-color_1;
    box-shadow: 0 0 0 1px var(--primary-500);
    outline: 0;
    &::-ms-value {
      background-color: $background-color_1;
      color: $color_1;
    }
  }
  &:disabled {
    background-color: $background-color_3;
    color: $color_2;
  }
  &::-ms-expand {
    display: none;
  }
  &:-moz-focusring {
    color: $color_3;
    text-shadow: 0 0 0 #454545;
  }
}
.custom-select[multiple] {
  background-image: none;
  height: auto;
  padding-right: 1rem;
}
.custom-select[size] {
  &:not([size="1"]) {
    background-image: none;
    height: auto;
    padding-right: 1rem;
  }
}
.custom-select-sm {
  font-size: 0.875rem;
  height: calc(1.4286em + 0.875rem + 2px);
  padding-bottom: 0.4375rem;
  padding-top: 0.4375rem;
  padding-left: 0.75rem;
}
.custom-select-lg {
  font-size: 1.325rem;
  height: calc(1.5em + 1.375rem + 2px);
  padding-bottom: 0.6875rem;
  padding-top: 0.6875rem;
  padding-left: 1.25rem;
}
.custom-file {
  display: inline-block;
  margin-bottom: 0;
  height: calc(1.3333em + 1.125rem + 2px);
  position: relative;
  width: 100%;
}
.custom-file-input {
  height: calc(1.3333em + 1.125rem + 2px);
  position: relative;
  width: 100%;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  z-index: 2;
  &:focus {
    ~ {
      .custom-file-label {
        border-color: $border-color_1;
        box-shadow: 0 0 0 1px var(--primary-500);
      }
    }
  }
  &:disabled {
    ~ {
      .custom-file-label {
        background-color: $background-color_3;
      }
    }
  }
  &:lang(en) {
    ~ {
      .custom-file-label {
        &::after {
          content: "Browse";
        }
      }
    }
  }
  ~ {
    .custom-file-label[data-browse] {
      &::after {
        content: attr(data-browse);
      }
    }
  }
}
.custom-file-input[disabled] {
  ~ {
    .custom-file-label {
      background-color: $background-color_3;
    }
  }
}
.custom-file-label {
  background-color: $background-color_1;
  border: 1px solid #707070;
  border-radius: 0.375rem;
  font-weight: 400;
  height: calc(1.3333em + 1.125rem + 2px);
  left: 0;
  overflow: hidden;
  right: 0;
  z-index: 1;
  color: $color_1;
  line-height: 1.3333;
  padding: 0.5625rem 1rem;
  position: absolute;
  top: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &::after {
    color: $color_1;
    line-height: 1.3333;
    padding: 0.5625rem 1rem;
    position: absolute;
    top: 0;
    background-color: $background-color_3;
    bottom: 0;
    content: "Browse";
    display: block;
    height: calc(1.3333em + 1.125rem);
    z-index: 3;
    border-left: inherit;
    border-radius: 0 0.375rem 0.375rem 0;
    right: 0;
  }
}
.custom-range {
  -webkit-appearance: none;
  appearance: none;
  background-color: $background-color_2;
  height: calc(1rem + 2px);
  padding: 0;
  width: 100%;
  &:focus {
    outline: 0;
    &::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 1px var(--primary-500);
    }
    &::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 1px var(--primary-500);
    }
    &::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 1px var(--primary-500);
    }
  }
  &::-moz-focus-outer {
    border: 0;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: $background-color_8;
    border: 0;
    border-radius: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 1rem;
    &:active {
      background-color: $background-color_11;
    }
  }
  &::-webkit-slider-runnable-track {
    background-color: $background-color_12;
    border-color: $border-color_6;
    border-radius: 1rem;
    color: $color_3;
    cursor: pointer;
    height: 0.5rem;
    width: 100%;
  }
  &::-moz-range-thumb {
    appearance: none;
    background-color: $background-color_8;
    border: 0;
    border-radius: 1rem;
    height: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 1rem;
    &:active {
      background-color: $background-color_11;
    }
  }
  &::-moz-range-track {
    background-color: $background-color_12;
    border-color: $border-color_6;
    border-radius: 1rem;
    color: $color_3;
    cursor: pointer;
    height: 0.5rem;
    width: 100%;
  }
  &::-ms-thumb {
    appearance: none;
    background-color: $background-color_8;
    border: 0;
    border-radius: 1rem;
    height: 1rem;
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 0;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 1rem;
    &:active {
      background-color: $background-color_11;
    }
  }
  &::-ms-track {
    background-color: $background-color_2;
    border-color: $border-color_6;
    border-width: 0.5rem;
    color: $color_3;
    cursor: pointer;
    height: 0.5rem;
    width: 100%;
  }
  &::-ms-fill-lower {
    background-color: $background-color_12;
    border-radius: 1rem;
  }
  &::-ms-fill-upper {
    background-color: $background-color_12;
    border-radius: 1rem;
    margin-right: 15px;
  }
  &:disabled {
    &::-webkit-slider-thumb {
      background-color: $background-color_13;
    }
    &::-webkit-slider-runnable-track {
      cursor: default;
    }
    &::-moz-range-thumb {
      background-color: $background-color_13;
    }
    &::-moz-range-track {
      cursor: default;
    }
    &::-ms-thumb {
      background-color: $background-color_13;
    }
  }
}
.pgn__form-text {
  align-items: center;
  display: flex;
  font-size: 87.5%;
  font-size: 0.75rem;
  .pgn__icon {
    display: inline-block;
    height: 1em;
    margin-inline-end: 0.25em;
    width: 1em;
  }
  ~ {
    .pgn__form-text {
      margin-top: 0.25rem;
    }
  }
}
.pgn__form-control-decorator-group {
  + {
    .pgn__form-text {
      margin-top: 0.25rem;
    }
  }
  align-items: flex-start;
  display: flex;
  flex-grow: 1;
  font-size: 1.125rem;
  line-height: 1.3333;
  position: relative;
}
.pgn__form-control-set {
  + {
    .pgn__form-text {
      margin-top: 0.25rem;
    }
  }
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  >* {
    + {
      * {
        margin: 0.75rem 0 0;
      }
    }
  }
}
.pgn__form-text-default {
  color: $color_2;
}
.pgn__form-text-valid {
  color: $color_4;
}
.pgn__form-text-invalid {
  color: $color_6;
}
.pgn__form-text-warning {
  color: $color_8;
}
.pgn__form-text-criteria-empty {
  .pgn__icon {
    color: $color_2;
  }
}
.pgn__form-text-criteria-valid {
  .pgn__icon {
    color: $color_4;
  }
}
.pgn__form-text-criteria-invalid {
  .pgn__icon {
    color: $color_6;
  }
}
.pgn__form-control-set-inline {
  align-items: flex-start;
  flex-flow: row wrap;
}
.form-control.is-invalid.is-invalid-nodanger {
  border-color: $border-color_7;
  &:focus {
    background-color: $background-color_1;
    border-color: $border-color_1;
    box-shadow: 0 0 0 1px var(--primary-500);
    color: $color_1;
    outline: 0;
  }
}
.pgn__form-group {
  margin-bottom: 1rem;
  text-align: start;
  margin-bottom: 1.75rem;
  .form-check {
    padding-left: 0;
    padding-right: 1.25rem;
    .form-check-input {
      margin-left: 0;
      margin-right: -1.25rem;
    }
  }
}
.pgn__form-control-decorator-group.has-prepended-node {
  .form-control {
    padding-inline-start: calc(1rem + 32px);
    background-position: left 0.5625rem center;
  }
  .form-control-lg {
    padding-inline-start: calc(1.25rem + 32px);
  }
  .form-control-sm {
    padding-inline-start: calc(0.75rem + 32px);
  }
}
.pgn__form-control-decorator-group.has-appended-node {
  .form-control {
    padding-inline-end: calc(1rem + 32px);
  }
  .form-control-lg {
    padding-inline-end: calc(1.25rem + 32px);
  }
  .form-control-sm {
    padding-inline-end: calc(0.75rem + 32px);
  }
}
.pgn__form-control-decorator-group.pgn__form-control-decorator-group-sm {
  font-size: 0.875rem;
  line-height: 1.4286;
}
.pgn__form-control-decorator-group.pgn__form-control-decorator-group-lg {
  font-size: 1.325rem;
  line-height: 1.5;
}
.pgn__form-control-decorator {
  align-items: center;
  display: flex;
  font-size: inherit;
  height: calc(1.3333em + 1.125rem + 2px);
  justify-content: center;
  line-height: inherit;
  min-width: calc(1.3333em + 1.125rem + 2px);
  pointer-events: none;
  position: absolute;
  button {
    pointer-events: auto;
  }
  .btn-icon {
    color: $color_2 !important;
    &:hover {
      background-color: $background-color_13 !important;
      color: $color_5 !important;
    }
    &:focus {
      background-color: $background-color_1 !important;
      box-shadow: inset 0 0 0 2px #707070 !important;
      color: $color_2 !important;
    }
  }
}
.pgn__form-control-decorator.pgn__form-control-decorator-leading {
  inset-inline-start: 0;
  padding-inline-end: 0.5rem;
  padding-inline-start: 1rem;
}
.pgn__form-control-decorator.pgn__form-control-decorator-trailing {
  inset-inline-end: 0;
  padding-inline-end: calc(0.4375rem - 2px);
  padding-inline-start: 0.5rem;
}
.pgn__form-control-decorator-group-lg {
  .pgn__form-control-decorator {
    height: calc(1.5em + 1.375rem + 2px);
    min-width: calc(1.5em + 1.375rem + 2px);
  }
  .pgn__form-control-decorator.pgn__form-control-decorator-leading {
    padding-left: 1.25rem;
    padding-right: 0.625rem;
  }
  .pgn__form-control-decorator.pgn__form-control-decorator-trailing {
    padding-left: 0.625rem;
    padding-right: calc(0.5625rem - 2px);
  }
  .pgn__form-control-floating-label {
    padding: 0 1.25rem;
    .pgn__form-control-floating-label-content {
      font-size: 1.325rem;
      line-height: 1.5;
      padding-top: 0.6875rem;
    }
  }
}
.pgn__form-control-decorator-group-sm {
  .pgn__form-control-decorator {
    height: calc(1.4286em + 0.875rem + 2px);
    min-width: calc(1.5em + 1.375rem + 2px);
  }
  .pgn__form-control-decorator.pgn__form-control-decorator-leading {
    padding-left: 0.75rem;
    padding-right: 0.375rem;
  }
  .pgn__form-control-decorator.pgn__form-control-decorator-trailing {
    padding-left: 0.375rem;
    padding-right: calc(0.4375rem - 2px);
  }
  .pgn__form-control-floating-label {
    padding: 0 0.75rem;
    .pgn__form-control-floating-label-content {
      font-size: 0.875rem;
      line-height: 1.4286;
      padding-top: 0.4375rem;
    }
  }
}
.pgn__form-control-floating-label {
  display: flex;
  height: 100%;
  inset-inline-end: 0;
  inset-inline-start: 1px;
  padding: 0 1rem;
  pointer-events: none;
  position: absolute;
  width: 100%;
  .pgn__form-control-floating-label-content {
    border-top: 2px solid var(--mono-1000);
    inset-inline-start: -0.5em;
    max-width: 100%;
    position: relative;
    font-size: 1.125rem;
    line-height: 1.3333;
    padding-top: 0.5625rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .pgn__form-control-floating-label-text {
    background-color: $background-color_14;
    display: block;
    max-width: 75vw;
    overflow: hidden;
    padding: 0 0.5em;
    text-overflow: ellipsis;
    transition: transform 0.15s ease, background-color 0.15s linear;
    white-space: nowrap;
    transform-origin: left top;
    font-size: rem-calc(14);
  }
}
.has-leading-element {
  .pgn__form-control-floating-label {
    .pgn__form-control-floating-label-text {
      transform: translateX(32px);
      transform: translateX(-32px);
    }
  }
}
.form-control.has-value {
  ~ {
    .pgn__form-control-floating-label {
      .pgn__form-control-floating-label-text {
        background-color: $background-color_1;
        transform: translateY(-0.5625rem) scale(0.75) translateY(-80.0007500188%);
      }
    }
  }
}
.float-label.pgn__form-control-decorator-group-lg {
  .pgn__form-control-floating-label {
    .pgn__form-control-floating-label-text {
      transform: translateY(-0.6875rem) scale(0.75) translateY(-76.6666666667%);
    }
  }
}
.float-label.pgn__form-control-decorator-group-sm {
  .pgn__form-control-floating-label {
    .pgn__form-control-floating-label-text {
      transform: translateY(-0.4375rem) scale(0.75) translateY(-77.9994400112%);
    }
  }
}
.pgn__form-control-decorator-group.has-floating-label {
  input[type="date"] {
    &:not(:focus) {
      &:not(.has-value) {
        color: $color_3;
      }
    }
    &::-webkit-calendar-picker-indicator {
      left: 0;
    }
  }
  input[type="time"] {
    &:not(:focus) {
      &:not(.has-value) {
        color: $color_3;
      }
    }
    &::-webkit-calendar-picker-indicator {
      left: 0;
    }
  }
  .form-control {
    &:not(:focus) {
      &:not(.has-value) {
        &::-webkit-datetime-edit {
          opacity: 0;
        }
        &::placeholder {
          opacity: 0;
        }
      }
    }
  }
  select.form-control {
    &:not(.has-value) {
      &:not(:focus) {
        color: $color_3;
      }
    }
  }
}
.pgn__form-label {
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}
.pgn__form-label.pgn__form-label-inline {
  display: inline-block;
  margin: 0 1em 0 0;
}
.pgn__form-label.pgn__form-label-lg {
  font-size: 1.325rem;
}
.pgn__form-label.pgn__form-label-sm {
  font-size: 0.875rem;
}
.pgn__form-checkbox-input {
  -webkit-appearance: none;
  appearance: none;
  background-color: $background-color_1;
  background-position: 50%;
  border: 2px solid #454545;
  border-radius: 0;
  height: 1rem;
  margin-inline-end: 0.5rem;
  width: 1rem;
  background-size: 2rem;
  &:not(:disabled) {
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
    }
  }
  &:disabled {
    opacity: 0.3;
  }
  &:checked {
    border-color: $border-color_1;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%230A3055' d='M21 3H3v18h18V3ZM10 17l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z'/%3E%3C/svg%3E");
  }
  &:indeterminate {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%230A3055' d='M21 3H3v18h18V3Zm-4 10H7v-2h10v2Z'/%3E%3C/svg%3E");
  }
  &:focus {
    border-color: $border-color_1;
    box-shadow: none;
    outline: none;
    position: relative;
    text-decoration: none;
    &::before {
      border: 0.125rem solid var(--primary-500);
      border-radius: 0.0625rem;
      bottom: -0.375rem;
      content: "";
      left: -0.375rem;
      position: absolute;
      right: -0.375rem;
      top: -0.375rem;
    }
  }
}
.pgn__form-radio-input {
  -webkit-appearance: none;
  appearance: none;
  background-color: $background-color_1;
  background-position: 50%;
  border: 2px solid #454545;
  border-radius: 0;
  height: 1rem;
  margin-inline-end: 0.5rem;
  width: 1rem;
  background-size: 2rem;
  border-radius: 50%;
  &:not(:disabled) {
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
    }
  }
  &:disabled {
    opacity: 0.3;
  }
  &:checked {
    border-color: $border-color_1;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%230A3055'/%3E%3C/svg%3E");
  }
  &:focus {
    border-color: $border-color_1;
    box-shadow: none;
    outline: none;
    position: relative;
    text-decoration: none;
    border-color: $border-color_8;
    &::before {
      border: 0.125rem solid var(--primary-500);
      border-radius: 0.0625rem;
      bottom: -0.375rem;
      content: "";
      left: -0.375rem;
      position: absolute;
      right: -0.375rem;
      top: -0.375rem;
      border-radius: 50%;
    }
  }
}
.pgn__form-switch-input {
  -webkit-appearance: none;
  appearance: none;
  background-color: $background-color_1;
  background-position: 50%;
  border: 2px solid #454545;
  border-radius: 0;
  height: 1rem;
  margin-inline-end: 0.5rem;
  width: 1rem;
  background-size: 2rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%230A3055'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  border-radius: 0.625rem;
  transition: background 0.15s ease;
  width: 2.1875rem;
  background-position: 0;
  &:not(:disabled) {
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
    }
  }
  &:disabled {
    opacity: 0.3;
  }
  &:checked {
    border-color: $border-color_1;
    background-color: $background-color_15;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFF'/%3E%3C/svg%3E");
    background-position: 100%;
  }
  &:indeterminate {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%230A3055'/%3E%3C/svg%3E");
    background-position: 50%;
  }
}
.pgn__form-switch-helper-text {
  color: $color_2;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}
.pgn__form-checkbox {
  display: inline-flex;
  .pgn__form-label {
    align-items: center;
    display: flex;
    line-height: 1.25rem;
    margin-bottom: 0;
  }
  input {
    &:disabled {
      ~ {
        * {
          opacity: 0.3;
        }
      }
    }
  }
}
.pgn__form-radio {
  display: inline-flex;
  .pgn__form-label {
    align-items: center;
    display: flex;
    line-height: 1.25rem;
    margin-bottom: 0;
  }
  input {
    &:disabled {
      ~ {
        * {
          opacity: 0.3;
        }
      }
    }
  }
}
.pgn__form-switch {
  display: inline-flex;
  .pgn__form-label {
    align-items: center;
    display: flex;
    line-height: 1.25rem;
    margin-bottom: 0;
  }
  input {
    &:disabled {
      ~ {
        * {
          opacity: 0.3;
        }
      }
    }
  }
}
.pgn__form-checkbox.pgn__form-control-invalid {
  input {
    border-color: $border-color_4;
    &:checked {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23C32D3A' d='M21 3H3v18h18V3ZM10 17l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z'/%3E%3C/svg%3E");
    }
  }
}
.pgn__form-radio.pgn__form-control-invalid {
  input {
    border-color: $border-color_4;
    &:checked {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23C32D3A'/%3E%3C/svg%3E");
    }
  }
}
.pgn__form-switch.pgn__form-control-invalid {
  input {
    border-color: $border-color_4;
  }
}
.pgn__form-checkbox.pgn__form-control-valid {
  input {
    border-color: $border-color_2;
    &:checked {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23178253' d='M21 3H3v18h18V3ZM10 17l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z'/%3E%3C/svg%3E");
    }
  }
}
.pgn__form-radio.pgn__form-control-valid {
  input {
    border-color: $border-color_2;
    &:checked {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23178253'/%3E%3C/svg%3E");
    }
  }
}
.pgn__form-switch.pgn__form-control-valid {
  input {
    border-color: $border-color_2;
  }
}
.pgn__form-checkbox.pgn__form-control-label-left {
  .pgn__form-label {
    margin-inline-end: 0.5rem;
    order: 1;
  }
  input {
    order: 2;
  }
}
.pgn__form-radio.pgn__form-control-label-left {
  .pgn__form-label {
    margin-inline-end: 0.5rem;
    order: 1;
  }
  input {
    order: 2;
  }
}
.pgn__form-switch.pgn__form-control-label-left {
  .pgn__form-label {
    margin-inline-end: 0.5rem;
    order: 1;
  }
  input {
    order: 2;
  }
}
.pgn__form-autosuggest__wrapper {
  position: relative;
  .pgn__form-control-decorator {
    .btn-icon {
      &:hover {
        fill: var(--mono-100);
      }
      &:focus {
        fill: #707070;
      }
    }
  }
  .form-control {
    min-height: 2.75rem;
  }
  .pgn__icon.btn-icon__icon {
    height: 2.4rem;
    width: 2.4rem;
  }
  .pgn__form-autosuggest__dropdown {
    background-color: $background-color_1;
    border-radius: 0.25rem;
    box-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15), 0 0 0.25rem rgba(0, 0, 0, 0.15);
    max-height: 15rem;
    overflow-y: scroll;
    position: absolute;
    top: 3.125rem;
    width: calc(100% - 0.5rem);
    z-index: 1000;
    .dropdown-item {
      display: block;
      max-width: 100%;
      overflow: hidden;
      padding: 0.625rem 1rem;
      text-align: start;
      text-overflow: ellipsis;
      &:active {
        color: $color_5;
      }
    }
    .pgn__form-autosuggest__dropdown-loading {
      padding: 6.25rem 0;
      text-align: center;
      .spinner-border {
        border: 0.125rem solid var(--mono-900);
        height: 1.25rem;
        width: 1.25rem;
        border-right-color: $border-right-color_1;
      }
    }
  }
  >.pgn__form-group {
    margin-bottom: 0 !important;
  }
}
#password-requirement-left {
  filter: drop-shadow(0 0.125rem 0.25rem rgba(0, 0, 0, 0.15)) drop-shadow(0 0.125rem 0.25rem rgba(0, 0, 0, 0.15)) !important;
  opacity: 1;
  right: 0.2rem !important;
  .tooltip-inner {
    background: var(--mono-100);
    color: $color_2;
    display: block;
  }
  .arrow {
    &::before {
      border-left-color: $border-left-color_1;
    }
  }
}
#password-requirement-top {
  bottom: 10px !important;
  display: flex;
  filter: drop-shadow(0 0.125rem 0.25rem rgba(0, 0, 0, 0.15)) drop-shadow(0 0.125rem 0.25rem rgba(0, 0, 0, 0.15)) !important;
  justify-content: center;
  opacity: 1;
  width: 90%;
  .tooltip-inner {
    background: var(--mono-100);
    color: $color_2;
    display: block;
    min-width: 464px !important;
  }
  .arrow {
    &::before {
      border-top-color: $border-top-color_1;
    }
  }
}
.logistration-button {
  color: $color_1;
  &:hover {
    color: $color_1;
    text-decoration: none;
  }
}
#forgot-password {

  &:hover {
    text-decoration: none;
  }
}
.pgn__hyperlink {
  text-align: start;
}
.pgn__hyperlink__external-icon {
  display: inline-block;
  margin-inline-start: 0.5rem;
  vertical-align: middle;
}
.register-button {
  min-width: 14.4rem;
}
.email-suggestion-alert-error {
  padding: 0.5rem 1rem;
  .email-suggestion__close {
    float: right;
    &:hover {
      cursor: pointer;
    }
  }
  .email-suggestion__text {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
  .alert-link {
    color: $color_7 !important;
    &:hover {
      color: $color_9 !important;
      text-decoration: underline;
    }
  }
}
.email-suggestion-alert-warning {
  color: $color_10 !important;
  &:hover {
    color: $color_9 !important;
    text-decoration: underline;
  }
}
.form-field--checkbox {
  margin-top: 2rem;
  margin-left: 3px;
  .pgn__form-label {
    font-size: 1rem;
    line-height: 2;
  }
}
.username-suggestions--chip {
  border-radius: .375rem;
  color: var(--mono-100);
  font-size: rem-calc(14);
  font-weight: 400;
  line-height: 1;
  margin: 0.25rem;
  padding: .5rem;
  word-break: break-word;
  text-transform: initial;
}
.username-suggestion--label {
  font-size: 0.85rem;
  line-height: 1;
  margin-right: 0.5rem;
}
.username-suggestions, .username-suggestions__error {
  margin: 0.5rem 0 0 0;
  position: relative;
  background-color: var(--mono-100);
  border: rem-calc(1) dashed var(--mono-600);
  padding: 0.5rem;
}
.username-suggestions__close__button {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
.username-scroll-suggested--form-field {
  display: inline-flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 20rem;
}
.recommendations-container__card-list {
  gap: 24px 24px;
  .recommendation-card {
    cursor: pointer;
    flex: 0 1 100%;
  }
}
.recommendations-container__heading {
  overflow-wrap: break-word;
}
.reset-password--button {
  width: 12rem;
}
.pp-page__button-width {
  min-width: 6rem;
}
.pp-page__support-link {
  font-size: 0.875rem;
  font-weight: 400;
}
.pp-page__heading {
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
}
.forgot-password--button {
  min-width: 6rem;
  margin-top: 1.4rem;
  margin-right: 1rem;
}
.login-button-width {
  margin-right: 1rem;
  min-width: 6rem;
}
.paragon-fieldset {
  margin-bottom: 1.5rem;
  .form-control {
    height: auto;
  }
  fieldset {
    legend {
      margin-bottom: 0;
      width: auto;
    }
  }
}
.pgn__searchfield {
  border: 0.0625rem solid #707070;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  .btn {
    &:focus-visible {
      outline: none;
      position: relative;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &::after {
        border: 0.3125rem double var(--mono-900);
        content: "";
        height: 100%;
        position: absolute;
        width: 100%;
      }
    }
  }
  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  &:active {
    border-color: $border-color_8;
  }
  &:hover {
    border-color: $border-color_8;
  }
  .form-control {
    border: none;
    border-radius: 0;
    height: calc(1.3333em + 1.125rem);
    &:focus {
      box-shadow: none;
    }
    &::-ms-clear {
      display: none;
      height: 0;
      width: 0;
    }
    &::-ms-reveal {
      display: none;
      height: 0;
      width: 0;
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
    &::-webkit-search-decoration {
      display: none;
    }
    &::-webkit-search-results-button {
      display: none;
    }
    &::-webkit-search-results-decoration {
      display: none;
    }
    .form-control {
      &:active {
        border: none;
      }
      &:focus {
        border: none;
      }
      &:hover {
        border: none;
      }
    }
  }
  label.has-label-text {
    padding-inline-start: 0.75rem;
  }
  .pgn__searchfield_wrapper {
    align-items: center;
    border: 0.0625rem solid #707070;
    display: flex;
    width: 100%;
    &:active {
      border-color: $border-color_8;
    }
    &:hover {
      border-color: $border-color_8;
    }
  }
}
.pgn__searchfield.disabled {
  opacity: 0.3;
  pointer-events: none;
}
.pgn__searchfield.has-focus {
  &:not(.pgn__searchfield--external) {
    position: relative;
    &::after {
      border: 0.3125rem double var(--mono-900);
      content: "";
      height: 100%;
      position: absolute;
      width: 100%;
    }
    .pgn__searchfield_wrapper {
      box-shadow: 0 0 0 0.0625rem var(--mono-900);
    }
  }
}
.pgn__searchfield.pgn__searchfield--external {
  border: none;
  .btn-primary {
    background: var(--primary-500);
  }
  .btn-brand {
    background: var(--primary-500);
  }
}
.pgn__searchfield.pgn__searchfield--external.has-focus {
  box-shadow: none;
  .pgn__searchfield_wrapper {
    position: relative;
    &::after {
      border: 0.3125rem double var(--mono-900);
      content: "";
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }
  .btn-primary {
    background: var(--primary-500);
  }
  .btn-brand {
    background: var(--primary-500);
  }
}
.pgn__searchfield__button.btn[type="submit"] {
  border-radius: 0;
  margin-inline-start: 0.5rem;
}
.form-group__form-field {
  .form-control {
    &:focus {
      ~ {
        .pgn__form-control-floating-label {
          .pgn__form-control-floating-label-content {
            color: $color_11;
            font-size: 16px;
          }
        }
      }
    }
    &:not([value=""]) {
      &:not(:focus) {
        ~ {
          .pgn__form-control-floating-label {
            .pgn__form-control-floating-label-content {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
.form-text-size {
  line-height: 1.25rem;
  margin-top: 0.188rem !important;
}
#honor-code {
  margin-bottom: 1rem;
  p {
    margin: 0;
    padding: 0;
  }
}
.pgn__form-control-decorator-trailing {
  right: 0 !important;
}
.micro {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
}
.text-muted {
  color: $color_12;
}
#forget-password-form {
  h2 {
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 1.15;
    letter-spacing: 0.05ex;
    word-spacing: 0.25ex;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  p.small {
    margin-top: 2rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
  .custom-switch {
    .custom-control-label {
      &::after {
        transition: none;
      }
    }
  }
  .custom-range {
    &::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none;
    }
    &::-moz-range-thumb {
      -moz-transition: none;
      transition: none;
    }
    &::-ms-thumb {
      -ms-transition: none;
      transition: none;
    }
  }
  .custom-control-label {
    &::before {
      transition: none;
    }
  }
  .custom-file-label {
    transition: none;
  }
  .custom-select {
    transition: none;
  }
}
@media (min-width: 576px) {
  .form-inline {
    label {
      justify-content: center;
      align-items: center;
      display: flex;
      margin-bottom: 0;
    }
    .form-group {
      align-items: center;
      display: flex;
      margin-bottom: 0;
      flex: 0 0 auto;
      flex-flow: row wrap;
    }
    .form-control {
      display: inline-block;
      vertical-align: middle;
      width: auto;
    }
    .form-control-plaintext {
      display: inline-block;
    }
    .custom-select {
      width: auto;
    }
    .input-group {
      width: auto;
    }
    .form-check {
      align-items: center;
      display: flex;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-check-input {
      flex-shrink: 0;
      margin-top: 0;
      position: relative;
      margin-left: 0;
      margin-right: 0.25rem;
    }
    .custom-control {
      align-items: center;
      justify-content: center;
    }
    .custom-control-label {
      margin-bottom: 0;
    }
  }
  .pgn__form-autosuggest__wrapper {
    .pgn__form-autosuggest__dropdown {
      margin-inline-end: 0.5rem;
    }
  }
  .recommendations-container__card-list {
    .recommendation-card {
      flex: 0 1 calc(50% - 12px);
    }
  }
}
@media (max-width: 372px) {
  .username-scroll-suggested--form-field {
    width: 11rem !important;
  }
  .username-suggestions__close__button {
    right: 0.5rem;
  }
}
@media (max-width: 576px) {
  .username-scroll-suggested--form-field {
    width: 15rem;
  }
  .username-suggestions__close__button {
    right: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .recommendations-container__card-list {
    margin-bottom: 0 !important;
  }
}
@media (min-width: 768px) {
  .recommendations-container__card-list {
    .recommendation-card {
      flex: 0 1 calc(33.333% - 16px);
    }
  }
}
@media (min-width: 992px) {
  .recommendations-container__card-list {
    .recommendation-card {
      flex: 0 1 calc(25% - 18px);
    }
  }
}
@media (max-width: 991.98px) {
  .pp-page__heading {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
@media (max-width: 464px) {
  .pp-page__support-link {
    font-size: 0.688rem;
    font-weight: 400;
    line-height: 0.938rem;
  }
}
