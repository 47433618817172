// | -------------------------------------------------------------
// | > Reset
// | -------------------------------------------------------------

html {
  color: var(--mono-900);
  box-sizing: border-box;

  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }

  @media (prefers-reduced-motion: reduce) {
    body * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

blockquote,
body,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
li,
ol,
p,
pre,
ul {
  margin: 0;
  padding: 0;
}

// TODO: Revisit list-style-none rules (See: _list.scss)

// ul:where([class]) {
//   list-style: none;
// }

button,
input,
select,
textarea {
  color: inherit;
  letter-spacing: inherit;
  font: inherit;
}

input[type="text"],
textarea {
  width: 100%;
}

fieldset {
  padding: 0;
  border: none;
}

legend {
  margin-bottom: 0.5rem;
  max-width: 100%;
}

button,
input,
textarea {
  border: 1px solid gray;
}

button {
  padding: 0.75em 1em;
  border-radius: 0;
  background-color: transparent;
  line-height: 1;
}

button * {
  pointer-events: none;
}

button:hover {
  cursor: pointer;
}

dd {
  margin-left: 0;
}

embed,
img,
object,
picture,
svg,
video {
  height: auto;
}

embed,
iframe,
img,
object,
picture,
svg,
video {
  display: inline-block;
  width: auto;
  max-width: 100%;
  vertical-align: middle;
}

table {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

address {
  font-style: normal;
}

[hidden] {
  display: none !important;
}

[hidden] + *,
.visually-hidden + * {
  all: unset;
}

noscript {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}

[tabindex="-1"] {
  outline: none !important;
  box-shadow: none !important;
}
