@use 'base/breakpoints' as *;
@use 'base/functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

$color_1: #80b6d5;
$color_2: #006daa;
$color_3: var(--mono-900);
$color_4: #454545;
$color_5: inherit;
$color_6: var(--primary-500);
$color_7: var(--primary-500);
$color_8: var(--green-600);
$color_9: #ffd900;
$color_10: var(--red-600);
$color_11: #e1dddb;
$color_12: var(--green-700);
$color_13: #707070;
$color_14: #00bbf9;
$color_15: #fe8;
$color_16: var(--mono-100);
$background-color_1: #f0f3f5;
$background-color_2: #f4f4f4;
$background-color_3: #f9f0f5;
$background-color_4: #f1f8f5;
$background-color_5: #f0f6fa;
$background-color_6: #fffdf0;
$background-color_7: #fbf2f3;
$background-color_8: #fdfdfd;
$background-color_9: #f2f3f3;
$background-color_10: #ebebeb;
$background-color_11: #00bbf9;
$background-color_12: #fe8;
$background-color_13: var(--green-600);
$background-color_14: var(--red-600);
$background-color_15: var(--mono-100);
$background-color_16: var(--green-700);
$border-color_1: #c2cbd5;
$border-color_2: #d1d1d1;
$border-color_3: #e7bfd4;
$border-color_4: #c5e0d4;
$border-color_5: #bfdbea;
$border-color_6: #fff6bf;
$border-color_7: #f0cbce;
$border-color_8: #f8f7f6;
$border-color_9: #c9cfcb;
$border-color_10: #ccc;
$border-color_11: #00bbf9;
$border-color_12: #fe8;
$border-color_13: #0000 #0000 var(--green-600);
$border-color_14: #0000 #0000 #fe8;
$border-color_15: #0000 #0000 var(--red-600);
$border-color_16: #0000 #0000 var(--mono-100);
$border-color_17: #0000 #0000 var(--green-700);
$border-right-color_1: var(--green-600);
$border-right-color_2: #fe8;
$border-right-color_3: var(--red-600);
$border-right-color_4: var(--mono-100);
$border-right-color_5: var(--green-700);
$border-left-color_1: var(--green-600);
$border-left-color_2: #fe8;
$border-left-color_3: var(--red-600);
$border-left-color_4: var(--mono-100);
$border-left-color_5: var(--green-700);

.alert-link {
  color: $color_1 !important;
  font-weight: 400;
  text-decoration: underline;
  &:hover {
    color: $color_2 !important;
    text-decoration: none;
  }
}
.alert {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  border: 0 solid var(--mono-1000);
  border-radius: 0.375rem;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15), 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.15);
  margin-bottom: 1rem;
  padding: 1.5rem;
  position: relative;
  .alert-message-content {
    > :last-child {
      margin-bottom: 0;
    }
  }
  .alert-icon {
    flex-shrink: 0;
    height: 1.5rem;
    margin-inline-end: 0.8rem;
    width: 1.5rem;
    float: left;
  }
}
.alert-heading {
  color: $color_3;
  display: flex;
}
.alert-content {
  color: $color_4;
  display: flex;
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-align: start;
  div {
    flex-grow: 1;
  }
}
.alert-dismissible {
  padding-right: 4.6875rem;
  .close {
    color: $color_5;
    padding: 1.5rem;
    position: absolute;
    top: 0;
    right: 0;
  }
}
.pgn__alert-message-wrapper {
  align-items: center;
  display: flex;
  .pgn__alert-actions {
    margin-inline-start: 1rem;
  }
}
.pgn__alert-message-wrapper-stacked {
  .pgn__alert-actions {
    margin-top: 1rem;
  }
}
.alert-primary {
  background-color: $background-color_1;
  border-color: $border-color_1;
  color: $color_5;
  .alert-icon {
    color: $color_6;
  }
}
.alert-secondary {
  background-color: $background-color_2;
  border-color: $border-color_2;
  color: $color_5;
  .alert-icon {
    color: $color_4;
  }
}
.alert-brand {
  background-color: $background-color_3;
  border-color: $border-color_3;
  color: $color_5;
  .alert-icon {
    color: $color_7;
  }
}
.alert-success {
  background-color: $background-color_4;
  border-color: $border-color_4;
  color: $color_5;
  .alert-icon {
    color: $color_8;
  }
}
.alert-info {
  background-color: $background-color_5;
  border-color: $border-color_5;
  color: $color_5;
  .alert-icon {
    color: $color_2;
  }
}
.alert-warning {
  background-color: $background-color_6;
  border-color: $border-color_6;
  color: $color_5;
  .alert-icon {
    color: $color_9;
  }
}
.alert-danger {
  background-color: $background-color_7;
  border-color: $border-color_7;
  color: $color_5;
  .alert-icon {
    color: $color_10;
  }
}
.alert-light {
  background-color: $background-color_8;
  border-color: $border-color_8;
  color: $color_5;
  .alert-icon {
    color: $color_11;
  }
}
.alert-dark {
  background-color: $background-color_9;
  border-color: $border-color_9;
  color: $color_5;
  .alert-icon {
    color: $color_12;
  }
}
.alert-gray {
  background-color: $background-color_10;
  border-color: $border-color_10;
  color: $color_5;
  .alert-icon {
    color: $color_13;
  }
}
.alert-accent-a {
  background-color: $background-color_11;
  border-color: $border-color_11;
  color: $color_5;
  .alert-icon {
    color: $color_14;
  }
}
.alert-accent-b {
  background-color: $background-color_12;
  border-color: $border-color_12;
  color: $color_5;
  .alert-icon {
    color: $color_15;
  }
}
.pgn__annotation {
  word-wrap: break-word;
  border-radius: 0.25rem;
  display: inline-block;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.15))
    drop-shadow(0 2px 8px rgba(0, 0, 0, 0.15));
  font-size: 0.875rem;
  line-height: 1.5;
  max-width: 18.75rem;
  padding: 0.5rem;
  position: relative;
  text-align: center;
}
.pgn__annotation-success-top {
  background-color: $background-color_13;
  color: $color_16;
  margin-top: 0.75rem;
  &:after {
    border: solid;
    border-color: $border-color_13;
    border-width: 0 0.5rem 0.5rem;
    content: "";
    height: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: -0.5rem;
    width: 0;
  }
}
.pgn__annotation-success-right {
  background-color: $background-color_13;
  color: $color_16;
  margin-right: 0.75rem;
  &:after {
    border: solid var(--mono-1000);
    bottom: 0;
    content: "";
    height: 0;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 0;
    border-left-color: $border-left-color_1;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    right: -0.5rem;
  }
}
.pgn__annotation-success-bottom {
  background-color: $background-color_13;
  color: $color_16;
  margin-bottom: 0.75rem;
  &:after {
    border: solid var(--mono-1000);
    border-top: solid var(--green-600);
    border-width: 0.5rem 0.5rem 0;
    bottom: -0.5rem;
    content: "";
    height: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 0;
  }
}
.pgn__annotation-success-left {
  background-color: $background-color_13;
  color: $color_16;
  margin-left: 0.75rem;
  &:after {
    border: solid var(--mono-1000);
    bottom: 0;
    content: "";
    height: 0;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 0;
    border-right-color: $border-right-color_1;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    left: -0.5rem;
  }
}
.pgn__annotation-warning-top {
  background-color: $background-color_12;
  color: $color_3;
  margin-top: 0.75rem;
  &:after {
    border: solid;
    border-color: $border-color_14;
    border-width: 0 0.5rem 0.5rem;
    content: "";
    height: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: -0.5rem;
    width: 0;
  }
}
.pgn__annotation-warning-right {
  background-color: $background-color_12;
  color: $color_3;
  margin-right: 0.75rem;
  &:after {
    border: solid var(--mono-1000);
    bottom: 0;
    content: "";
    height: 0;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 0;
    border-left-color: $border-left-color_2;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    right: -0.5rem;
  }
}
.pgn__annotation-warning-bottom {
  background-color: $background-color_12;
  color: $color_3;
  margin-bottom: 0.75rem;
  &:after {
    border: solid var(--mono-1000);
    border-top: solid #fe8;
    border-width: 0.5rem 0.5rem 0;
    bottom: -0.5rem;
    content: "";
    height: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 0;
  }
}
.pgn__annotation-warning-left {
  background-color: $background-color_12;
  color: $color_3;
  margin-left: 0.75rem;
  &:after {
    border: solid var(--mono-1000);
    bottom: 0;
    content: "";
    height: 0;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 0;
    border-right-color: $border-right-color_2;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    left: -0.5rem;
  }
}
.pgn__annotation-error-top {
  background-color: $background-color_14;
  color: $color_16;
  margin-top: 0.75rem;
  &:after {
    border: solid;
    border-color: $border-color_15;
    border-width: 0 0.5rem 0.5rem;
    content: "";
    height: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: -0.5rem;
    width: 0;
  }
}
.pgn__annotation-error-right {
  background-color: $background-color_14;
  color: $color_16;
  margin-right: 0.75rem;
  &:after {
    border: solid var(--mono-1000);
    bottom: 0;
    content: "";
    height: 0;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 0;
    border-left-color: $border-left-color_3;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    right: -0.5rem;
  }
}
.pgn__annotation-error-bottom {
  background-color: $background-color_14;
  color: $color_16;
  margin-bottom: 0.75rem;
  &:after {
    border: solid var(--mono-1000);
    border-top: solid var(--red-600);
    border-width: 0.5rem 0.5rem 0;
    bottom: -0.5rem;
    content: "";
    height: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 0;
  }
}
.pgn__annotation-error-left {
  background-color: $background-color_14;
  color: $color_16;
  margin-left: 0.75rem;
  &:after {
    border: solid var(--mono-1000);
    bottom: 0;
    content: "";
    height: 0;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 0;
    border-right-color: $border-right-color_3;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    left: -0.5rem;
  }
}
.pgn__annotation-light-top {
  background-color: $background-color_15;
  color: $color_6;
  margin-top: 0.75rem;
  &:after {
    border: solid;
    border-color: $border-color_16;
    border-width: 0 0.5rem 0.5rem;
    content: "";
    height: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: -0.5rem;
    width: 0;
  }
}
.pgn__annotation-light-right {
  background-color: $background-color_15;
  color: $color_6;
  margin-right: 0.75rem;
  &:after {
    border: solid var(--mono-1000);
    bottom: 0;
    content: "";
    height: 0;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 0;
    border-left-color: $border-left-color_4;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    right: -0.5rem;
  }
}
.pgn__annotation-light-bottom {
  background-color: $background-color_15;
  color: $color_6;
  margin-bottom: 0.75rem;
  &:after {
    border: solid var(--mono-1000);
    border-top: solid var(--mono-100);
    border-width: 0.5rem 0.5rem 0;
    bottom: -0.5rem;
    content: "";
    height: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 0;
  }
}
.pgn__annotation-light-left {
  background-color: $background-color_15;
  color: $color_6;
  margin-left: 0.75rem;
  &:after {
    border: solid var(--mono-1000);
    bottom: 0;
    content: "";
    height: 0;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 0;
    border-right-color: $border-right-color_4;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    left: -0.5rem;
  }
}
.pgn__annotation-dark-top {
  background-color: $background-color_16;
  color: $color_16;
  margin-top: 0.75rem;
  &:after {
    border: solid;
    border-color: $border-color_17;
    border-width: 0 0.5rem 0.5rem;
    content: "";
    height: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: -0.5rem;
    width: 0;
  }
}
.pgn__annotation-dark-right {
  background-color: $background-color_16;
  color: $color_16;
  margin-right: 0.75rem;
  &:after {
    border: solid var(--mono-1000);
    bottom: 0;
    content: "";
    height: 0;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 0;
    border-left-color: $border-left-color_5;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    right: -0.5rem;
  }
}
.pgn__annotation-dark-bottom {
  background-color: $background-color_16;
  color: $color_16;
  margin-bottom: 0.75rem;
  &:after {
    border: solid var(--mono-1000);
    border-top: solid var(--green-700);
    border-width: 0.5rem 0.5rem 0;
    bottom: -0.5rem;
    content: "";
    height: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 0;
  }
}
.pgn__annotation-dark-left {
  background-color: $background-color_16;
  color: $color_16;
  margin-left: 0.75rem;
  &:after {
    border: solid var(--mono-1000);
    bottom: 0;
    content: "";
    height: 0;
    margin: auto 0;
    position: absolute;
    top: 0;
    width: 0;
    border-right-color: $border-right-color_5;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    left: -0.5rem;
  }
}
