@use 'base/breakpoints' as *;
@use 'base/functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

// Static / info pages

.main-panel {
  display: grid;
  background-color: var(--mono-100);
  border: 1px solid var(--mono-400);
  border-radius: 3px;

  > article,
  > aside {
    padding: 1.4rem;
  }

  h1,
  h2,
  h3,
  p,
  li {
    text-wrap: pretty;
  }

  p,
  ul:not([class]) li {
    font-size: 1.125rem;
    line-height: 1.5;
  }


  > ul {
    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  ul:not([class]) {
    padding-left: 1rem;

    & + * {
      margin-top: 1.2rem;
    }

    li {
      padding-left: 0.2rem;
    }
  }

  @include breakpoint(large, min) {
    grid-template-columns: 4fr 2fr;
    grid-template-areas:
      "article aside";
    gap: 1.4rem;

    > article {
      grid-area: article;
      padding: 2rem;
    }

    > aside {
      grid-area: aside;
      padding-top: 2rem;
    }
  }

  .main-header {

    h1 {
      font: 900 2.25rem/1.15 var(--font-family-custom);
      letter-spacing: 0.05ex;
      text-transform: uppercase;
    }

    & :only-child {
      margin-bottom: 1.4rem;
    }

  }

  article > .main-header {
    p:only-of-type {
      font: normal 1.75rem/1.5 var(--font-family-custom);
    }
  }

  .main-header + p:not([class]) {
    margin-top: 1.4rem;
  }

  .main-article,
  .main-section {

    h2 {
      font: 900 1.5rem/1.15 var(--font-family-custom);
      letter-spacing: 0.05ex;
      word-spacing: 0.25ex;
      text-transform: uppercase;

      + *:not(p) {
        margin-top: 1.4rem;
      }
    }

    h3 {
      font: 700 1.25rem/1.15 var(--font-family-custom);
      letter-spacing: 0.05ex;
      word-spacing: 0.25ex;
      text-transform: uppercase;
      margin-bottom: 0.8rem;
    }

  }

  .main-section {
    max-width: 43rem; // 80–85 char line length
  }

  * + .main-section {
    padding-top: 1.2rem;
    margin-top: 1.2rem;
  }

  // Social Impact

  .main-article {
    columns: 20rem 2;
    gap: 1.4rem;
    margin-top: 2.4rem;

    header {
      column-span: all;
      margin-bottom: 1.4rem;
    }

    section {
      display: inline-block;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 2.4rem;
      }
    }
  }

  // Sidebar

  > aside {
    border-top: 1px dashed var(--mono-300);

    h2 {
      font: 900 1.25rem/1.15 var(--font-family-custom);
      letter-spacing: 0.05ex;
      word-spacing: 0.25ex;
      text-transform: uppercase;
      margin-top: 0.25rem;
      margin-bottom: 0.8rem;
    }

    @include breakpoint(large, min) {
      border-top: none;
      border-left: 1px dashed var(--mono-300);
    }

  }

}

// back to (jump link)

.back-to {
  margin-top: 1.4rem;

  a::before {
    display: inline-block;
    content: "↑";
    font-family: monospace;
    color: var(--primary-500);
    vertical-align: top;
    margin-right: 0.2rem;
  }

  p {
    font-size: 0.9375rem;
    line-height: 1.15;
    letter-spacing: 0.025ex;
  }

  @include breakpoint(large, min) {
    display: none;
  }
}
