@use "sass:map";
@use "colors" as *;
@use "breakpoints" as *;
@use "functions" as *;

// via @https://jonnykates.medium.com/automating-colour-contrast-ratios-with-sass-e201f3b52797

// TODO: probably needs some refactoring before beinbg ready for prime time use
@mixin text-contrast($n) {
  $color-brightness: round((red($n) * 299) + (green($n) * 587) + (blue($n) * 114) / 1000);
  $light-color: round((red(#ffffff) * 299) + (green(#ffffff) * 587) + (blue(#ffffff) * 114) / 1000);
  @if abs($color-brightness) < ($light-color / 2){
    color: white;
  } @else {
    color: black;
  }
}

// TODO add a mixin to interchangeably convert hex, rgb, hsl



// we now use a separate color map for background colors to avoid CSS bloat.
@mixin bg-colors {
  @each $color, $shades in $bg-colors {
    @if (length($shades) > 1) {
      @each $shade, $value in $shades {
        .bg-#{$color}-#{$shade} {
          background-color: var(--#{$color}-#{$shade});
          --box-shadow-color: var(--#{$color}-#{$shade});

          // Automatically set the color based on the HSL lightness value
          @if lightness($value) > 60% {
            color: var(--mono-900);
          } @else {
            color: white;
          }
        }
      }
    } @else {
      @each $shade, $value in $shades {
        .bg-#{$color} {
          background-color: var(--#{$color}-#{$shade});
          --box-shadow-color: var(--#{$color}-#{$shade});

          // Automatically set the color based on the HSL lightness value
          @if lightness($value) > 60% {
            color: var(--mono-900);
          } @else {
            color: white;
          }
        }
      }
    }
  }
}

// Use CSS variables defined above to add box shadow color!
@mixin full-bleed {
  // box-shadow: -30rem 0 var(--box-shadow-color), 30rem 0 var(--box-shadow-color), -70rem 0 var(--box-shadow-color), 70rem 0 var(--box-shadow-color), -140rem 0 var(--box-shadow-color), 140rem 0 var(--box-shadow-color);
  // padding-left: 1rem; // Override .container padding-left/right
  // padding-right: 1rem; // "
  // margin-left: -1rem; // "
  // margin-right: -1rem; // "
}

@mixin dotted-bottom($border-color: var(--mono-500)) {
  position: relative;
  z-index: 2;
  overflow-y: visible;

  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 98.5vw;
    border-bottom: 1px dashed $border-color;
    z-index: -1;
  }
}

@mixin container {
  margin: 0 auto;
  padding: 0 calc(2rem / 2);
  max-width: 100%;

  @include breakpoint(medium) {
    padding: 0 2rem;
  }

  @include breakpoint(large) {
    padding: 0 calc(2rem / 2);
    max-width: convert-to-rem(map.get($breakpoints, 'large'));
  }

  &.stretch {
    max-width: 100%;
  }
}


// Adds grid support with fallbacks
@mixin card-grid {
  .card-grid {
    margin-top: 1.4rem;

    // --- legacy columns (inline-block) w/ breakpoints
    > ul {
      list-style: none;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;

      > li {
        overflow: hidden;
      }
    }
  }

  @include breakpoint(40em, min) {
    .card-grid {
      padding-bottom: 0;
      overflow: hidden;

      > ul {
        margin-left: -0.6rem;
        margin-right: -0.6rem;

        li {
          display: inline-block;
          width: 50%;
          vertical-align: top;
          box-sizing: border-box;
          padding-left: .6rem;
          padding-right: .6rem;
          margin-top: 1.2rem;
          margin-right: -4px;
        }
      }
    }
  }

  @include breakpoint(58em, min) {
    .card-grid > ul li {
      width: 50%;
    }
  }

  @include breakpoint(64em, min) {
    .card-grid > ul li {
      width: 33.333333333333333%;
    }

    // Hide 4th panel if it exists; see featured-courses.html
    .list-featured-courses li:last-child {
      display: none;
    }
  }

  @include breakpoint(78em, min) {
    .card-grid ul li {
      width: 25%;
    }

    .card-grid ul li:last-child {
      display: inline-block;
    }
  }

  // --- modern columns (grid)
  @supports (display: grid) {

    .card-grid > ul {
      display: grid;
      grid-template-columns: auto;
      grid-gap: 1.2rem;
    }

    @include breakpoint(36em, min) {
      .card-grid > ul {
        grid-template-columns: repeat(auto-fit, minmax(19.25em, 1fr));
      }

      .card-grid-max-2 ul {
        grid-template-columns: repeat(auto-fill, minmax(24em, 1fr));
      }

      .card-grid-max-3 ul {
        grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
      }
    }

   // Hide 4th panel if it exists; see featured-courses.html
    @include breakpoint(62em, min) {
      .list-featured-courses li:last-child {
        display: none;
      }
    }

    @include breakpoint(78em, min) {
      .card-grid ul li:last-child {
        display: inherit;
      }
    }

    // override -- legacy layout -- start

    .card-grid > ul li {
      overflow: visible;
    }

    .card-grid > ul,
    .card-grid > ul li {
      margin-top: 0;
      margin-right: 0;
    }

    .card-grid > ul {
      margin-left: 0;

      li {
        display: inherit;
        width: auto;
        box-sizing: content-box;
        padding-left: 0;
        padding-right: 0;

        &::before {
          content: normal;
        }
      }
    }
    // override -- legacy layout -- end

  }
}
