// | -------------------------------------------------------------
// | This file is intended to only serve the Authn MFE
// | -------------------------------------------------------------
@use 'base/breakpoints' as *;
@use 'base/functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

@use 'shared';
@use 'base/visibility';
@use 'components/webfonts_absolute';

@use 'mfe/root';
@use 'mfe/layout';

@use "mfe/flex";
@use "mfe/animations";
@use "mfe/authn/buttons";
@use "mfe/dropdowns";
@use "mfe/icons";
@use "mfe/tooltips";
@use "mfe/forms";
@use "mfe/tabs";
@use "mfe/notifications";
@use "mfe/headings";

.layout {
  display: grid;
  grid-template-rows: auto;
  margin: auto;
  min-height: 50vh; // temp fix to prevent footer from being halfway up the page

  @include breakpoint(medium) {
    max-width: 50vw;
  }

  @include breakpoint(large) {
    max-width: rem-calc(500);
  }
}
