// | -------------------------------------------------------------
// | Container
// | -------------------------------------------------------------
@use "sass:map";
@use "breakpoints" as *;
@use "functions" as *;
@use "mixins" as *;

.container {
  @include container;
}

main {
  background-color: var(--mono-200);
  padding-bottom: 1.4rem;

  &:focus {
    outline: none;
  }

  // TODO: replace with :not(.full-width) instead?
  &.default {
    padding-top: 1.4rem;
  }
}

// TODO: maybe this isn't the right place for this? We can move it accordingly as we see fit.
.embed-responsive {
  position: relative;
  height: 0;
  background-color: var(--mono-200);
  padding: 0;
  border: 1px solid var(--mono-300);
  overflow: hidden;

  // Loading: Wireframe gray box
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background:
      linear-gradient(to top right,
         transparent calc(50% - 1px), var(--mono-300) calc(50% - 1px),
         var(--mono-300) calc(50% + 1px), transparent calc(50% + 1px)),
      linear-gradient(to bottom right,
         transparent calc(50% - 1px), var(--mono-300) calc(50% - 1px),
         var(--mono-300) calc(50% + 1px), transparent calc(50% + 1px)),
         var(--mono-200);
  }

  .embed-responsive-item,
  embed,
  iframe,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  &-16by9 {
    padding-bottom: 56.25%;
  }

  &-4by3 {
    padding-bottom: 75%;
  }
}
