// | -------------------------------------------------------------
// | Lists
// | -------------------------------------------------------------

@use 'breakpoints' as *;

nav ul,
ul[class] {
  list-style: none linear-gradient(transparent, transparent);  // Fallback
  list-style: ""; // Re-add list semantics
  padding-left: 0;
}

ul,
ol {
  margin-left: 0;

  ul,
  ol {
    padding: 0.5rem 1rem 0;
  }

  li {
    margin-top: 0.5rem;
  }
}

ul {
  > li::marker {
    color: var(--secondary-500);
  }
}

ol.incremented {
  counter-reset: item;

  ol {
    counter-reset: item;
  }

  ol,
  ul {
    margin: 0.75rem 0 0 1rem;
  }

  li {
    display: block;
    margin-bottom: 0.5rem;

    @include breakpoint(medium) {
      margin-bottom: 0.75rem;
    }

    &::before {
      content: counters(item, '.') '. ';
      counter-increment: item;
    }

    &:last-child {
      margin-bottom: 0;
    }

    p {
      display: inline;
    }
  }

  ul {
    li::before {
      content: '';
    }
  }
}
