// | -------------------------------------------------------------
// | Layouts
// | -------------------------------------------------------------

@use 'base/breakpoints' as *;
@use 'functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

// good for headers
.grid-col-1-2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 2rem;
}

//
.grid-sidebar {
  display: grid;
  gap: 2rem;
  grid-template-areas:
    "content"
    "sidebar";

  @media (min-width: 52em) {
    grid-template-columns: 3fr 1fr;
    grid-template-areas:
      "content sidebar"
  }

  .content {
    grid-area: content;
    padding-top: 1rem;

    @media (min-width: 52em) {
      border-right: 1px dashed #ccc;
      padding-right: 1rem;
    }
  }

  aside {
    grid-area: sidebar;
    padding-top: 1rem;
  }
}

.color-card {
  display: grid;
  grid-template-rows: 1fr auto;
  background-color: var(--mono-900);
  border-top: 1rem solid var(--mono-500);
  color: var(--mono-100); // default color for all enclosed text , unless overriden by anything below

  &.magenta {
    border-top-color: var(--magenta-500);
  }

  &.purple {
    border-top-color: var(--purple-500);
  }

  &.teal {
    border-top-color: var(--teal-500);
  }

  &.green {
    border-top-color: var(--green-500);
  }

  &.orange {
    border-top-color: var(--secondary-500);
  }

  h2 {
    text-transform: uppercase;
    font-size: rem-calc(30);
    line-height: 1;
  }

  .main {
    padding: 1.2rem 1.2rem 0;

    header {
      max-width: 18.25rem;

      .title {
        color: var(--mono-100);
        line-height: 1.15;
        letter-spacing: 0.05ex;
        word-spacing: 0.05ex;
        text-wrap: balance;
      }

      .topic {
        font-size: 1rem;
        line-height: 1;
        color: var(--mono-400);
        letter-spacing: 0.15rem;
        margin-top: rem-calc(-1);
        margin-bottom: 1.2rem;
      }
    }

    .subhead {
      display: block;
      max-width: 24rem;
      font-weight: 700;
      font-size: 1.0625rem;
      line-height: 1.25;
      color: var(--mono-100);
      letter-spacing: 0.1ex;
      word-spacing: 0.1ex;
      margin-top: 0.6rem;
    }

    p {
      font-size: 1rem;
      color: var(--mono-300);
    }
  }

  // --- cta ---

  .cta {
    color: var(--mono-100);
    padding: 1.2em;

    p {
      font-size: 1rem;
      margin-top: 0;
    }
  }
}

// defined in mixins (used in collections/webinars)
@include card-grid();

// intro section for collections/webinars
// TODO: consider renaming?
.intro,
.intro-about {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;

  h1 {
    font-size: rem-calc(36);
    text-rendering: auto;
  }

  p {
    font-size: rem-calc(20);
  }
}


// Collections featured section
// Static pages sidebar content

.collection,
.main-panel {

  .featured {
    margin: 1.4rem 0 1.8rem;

    > ul {
      display: grid;
      gap: 1.4rem;

      @include breakpoint(medium, min) {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
      }

    }

    article {
      display: grid;
      grid-auto-rows: 1fr auto;
      height: 100%;

      > div {
        display: inherit;
        grid-template-rows: auto auto 1fr auto;
        background-color: var(--mono-100);
        padding: 1.4rem;
        border: 1px solid var(--mono-400);
        border-radius: 3px;
      }

      .topic {
        font: 900 1.8rem/1 var(--font-family-custom);
        text-transform: uppercase;
        letter-spacing: 0.0125ex;
        word-spacing: 0.25ex;

        b {
          font: inherit;
        }
      }

      figure {
        position: relative;
        height: 0;
        padding-bottom: 63.875%;
        margin: 1.4rem -1.4rem 1.2rem;
        overflow: hidden;

        &[style*="courses"] {
          border-image: 2 / 100% stretch;
        }

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          border-top: 1px solid var(--mono-300);
          border-bottom: 1px solid var(--mono-300);
        }
      }

      h3 {
        font-family: var(--font-family-custom);
        font-weight: 900;
        font-size: 1.5rem;
        line-height: 1.25;
        text-transform: uppercase;
        text-wrap: balance;
      }

      .cta {
        margin-top: 0.4rem;
      }

      footer {
        text-align: center;
        margin-top: 1rem;
      }

    }
  }

}

.main-panel {

  article.featured-card {

  > div {
    background-color: #f9f9f9; // WIP; TODO: Add ultralight gray swatch?

      .topic {
        font-size: 1.25rem;
      }
    }
  }

}
