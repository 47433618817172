@use 'base/breakpoints' as *;
@use 'base/functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

$color_1: #454545;
$color_2: #adadad;
$color_3: var(--mono-100);
$color_4: var(--primary-500);
$background-color_1: #e9e6e4;
$background-color_2: var(--primary-500);
$background-color_3: #939f97;
$background-color_4: var(--mono-100);
$background-color_5: var(--mono-1000);
$background-color_6: #8598aa;
$border-color_1: #0000 #0000 #e9e6e4;
$border-color_2: var(--mono-100);
$border-color_3: #939f97;
$border-color_4: var(--primary-500);
$border-color_5: var(--mono-1000);

.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 2rem;
  padding: 0;
}
.nav-tabs {
  .nav-link {
    background: var(--mono-1000);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: -2px;
    &:hover {
      background-color: $background-color_1;
    }
    .dropdown {
      padding-bottom: 0.125rem;
    }
    &:focus {
      border-bottom: 2px solid var(--mono-1000);
      outline: 0;
      position: relative;
      z-index: 200;
      &:before {
        border: 2px solid var(--primary-500);
        border-radius: 0;
        bottom: -4px;
        content: "";
        left: -4px;
        position: absolute;
        right: -4px;
        top: -4px;
      }
    }
  }
  border-bottom: 2px solid #e9e6e4;
  button.nav-link {
    border: none;
    border-bottom: 2px solid var(--mono-1000);
  }
  button.nav-link.disabled {
    border-color: $border-color_5;
  }
  .nav-link.disabled {
    background-color: $background-color_5;
    color: $color_2;
  }
  .nav-item.show {
    .nav-link {
      background-color: $background-color_5;
      border-bottom: 2px solid var(--primary-500);
      color: $color_4;
      &:hover {
        background-color: $background-color_1;
      }
      &:focus {
        outline: 0;
        position: relative;
        z-index: 200;
        &:before {
          border: 2px solid var(--primary-500);
          border-radius: 0;
          bottom: -4px;
          content: "";
          left: -4px;
          position: absolute;
          right: -4px;
          top: -4px;
        }
      }
    }
  }
  .nav-link.active {
    background-color: $background-color_5;
    border-bottom: 2px solid var(--primary-500);
    color: $color_4;
    &:hover {
      background-color: $background-color_1;
    }
    &:focus {
      outline: 0;
      position: relative;
      z-index: 200;
      &:before {
        border: 2px solid var(--primary-500);
        border-radius: 0;
        bottom: -4px;
        content: "";
        left: -4px;
        position: absolute;
        right: -4px;
        top: -4px;
      }
    }
  }
  .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -2px;
  }
}
.nav-link {
  color: $color_1;
  display: block;
  font-weight: 500;
  padding: 0.5rem 1rem;
  &:focus {
    color: $color_1;
    text-decoration: none;
  }
  &:hover {
    color: $color_1;
    text-decoration: none;
  }
}
.nav-link.disabled {
  color: $color_2;
  cursor: default;
  pointer-events: none;
}
.pgn__tabs.nav-pills {
  .nav-link {
    border: 1px solid #e9e6e4;
    &:focus {
      outline: 0;
      position: relative;
      z-index: 200;
      &:before {
        border: 2px solid var(--primary-500);
        border-radius: 0.375rem;
        bottom: -4px;
        content: "";
        left: -4px;
        position: absolute;
        right: -4px;
        top: -4px;
      }
    }
    &:hover {
      background-color: $background-color_1;
      border-color: $border-color_1;
    }
    .nav-item.show {
      .nav-link {
        background-color: $background-color_2;
        border-color: $border-color_2;
        color: $color_3;
        &:focus {
          outline: 0;
          position: relative;
          z-index: 200;
          &:before {
            border: 2px solid var(--primary-500);
            border-radius: 0.375rem;
            bottom: -4px;
            content: "";
            left: -4px;
            position: absolute;
            right: -4px;
            top: -4px;
          }
        }
      }
    }
  }
  .nav-link.active {
    background-color: $background-color_2;
    border-color: $border-color_2;
    color: $color_3;
    &:focus {
      outline: 0;
      position: relative;
      z-index: 200;
      &:before {
        border: 2px solid var(--primary-500);
        border-radius: 0.375rem;
        bottom: -4px;
        content: "";
        left: -4px;
        position: absolute;
        right: -4px;
        top: -4px;
      }
    }
  }
  .pgn__tab_more.nav-link {
    margin: 0;
    .dropdown {
      .dropdown-toggle {
        padding: 0.7rem 1rem;
        &:focus {
          background-color: $background-color_2;
          border-color: $border-color_4;
          color: $color_3;
        }
        &:focus.btn.btn-link {
          border-color: $border-color_4;
          color: $color_3;
        }
      }
    }
  }
  .pgn__tab_more.nav-link.active {
    .dropdown {
      .dropdown-toggle {
        color: $color_3;
      }
    }
    &:hover {
      .dropdown {
        .dropdown-toggle {
          background-color: $background-color_2;
        }
      }
    }
  }
}
.pgn__tabs.nav-inverse-pills {
  + {
    .tab-content {
      color: $color_3;
    }
  }
  .nav-link {
    border: 1px solid #939f97;
    color: $color_3;
    &:hover {
      background-color: $background-color_3;
    }
    &:focus {
      outline: 0;
      position: relative;
      z-index: 200;
      &:before {
        border: 2px solid var(--mono-100);
        border-radius: 0;
        bottom: -4px;
        content: "";
        left: -4px;
        position: absolute;
        right: -4px;
        top: -4px;
      }
    }
  }
  .nav-link.disabled {
    opacity: 0.5;
  }
  .nav-item.show {
    .nav-link {
      background-color: $background-color_4;
      border-color: $border-color_2;
      color: $color_4;
      &:hover {
        background-color: $background-color_3;
        border-color: $border-color_3;
        color: $color_3;
      }
      &:focus {
        border-color: $border-color_4;
        &:hover {
          background-color: $background-color_4;
          border-color: $border-color_4;
          color: $color_4;
        }
      }
    }
  }
  .nav-link.active {
    background-color: $background-color_4;
    border-color: $border-color_2;
    color: $color_4;
    &:hover {
      background-color: $background-color_3;
      border-color: $border-color_3;
      color: $color_3;
    }
    &:focus {
      border-color: $border-color_4;
      &:hover {
        background-color: $background-color_4;
        border-color: $border-color_4;
        color: $color_4;
      }
    }
  }
  .pgn__tab_more.nav-link {
    .dropdown {
      .dropdown-toggle {
        border: none;
        padding: 0.625rem 1rem;
        &:focus {
          background-color: $background-color_4;
          color: $color_4;
        }
      }
    }
  }
  .pgn__tab_more.nav-link.active {
    .dropdown {
      .dropdown-toggle {
        color: $color_4;
        &:hover {
          background-color: $background-color_6;
          color: $color_3;
        }
        &:focus {
          outline: 0;
          position: relative;
          z-index: 200;
          &:before {
            border: 2px solid var(--mono-100);
            border-radius: 0;
            bottom: -5px;
            content: "";
            left: -5px;
            position: absolute;
            right: -5px;
            top: -5px;
          }
        }
      }
    }
  }
}
.pgn__tabs.nav-inverse-tabs {
  + {
    .tab-content {
      color: $color_3;
    }
  }
  .nav-link {
    border-bottom: 2px solid #939f97;
    color: $color_3;
    .dropdown {
      .dropdown-toggle {
        border-bottom: 2px solid var(--mono-1000);
        &:hover {
          border-bottom: none;
        }
      }
    }
    &:hover {
      background-color: $background-color_3;
    }
    &:focus {
      outline: 0;
      position: relative;
      z-index: 200;
      &:before {
        border: 2px solid var(--mono-100);
        border-radius: 0;
        bottom: -4px;
        content: "";
        left: -4px;
        position: absolute;
        right: -4px;
        top: -4px;
      }
    }
  }
  .nav-link.disabled {
    opacity: 0.5;
  }
  .nav-item.show {
    .nav-link {
      background-color: $background-color_3;
      border-bottom: 2px solid var(--mono-100);
      &:hover {
        background-color: $background-color_5;
      }
    }
  }
  .nav-link.active {
    background-color: $background-color_3;
    border-bottom: 2px solid var(--mono-100);
    &:hover {
      background-color: $background-color_5;
    }
  }
  .pgn__tab_more.nav-link {
    .dropdown {
      .dropdown-toggle {
        padding: 0.625rem 1rem;
        &:hover {
          background-color: $background-color_5;
        }
      }
    }
  }
}
.nav-pills {
  .nav-link {
    border-radius: 0.375rem;
  }
  .nav-link.active {
    background-color: $background-color_2;
    color: $color_3;
  }
  .show {
    > .nav-link {
      background-color: $background-color_2;
      color: $color_3;
    }
  }
}
.nav-button-group {
  .nav-link {
    border: 1px solid #e9e6e4;
    &:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-left-radius: 0.375rem;
    }
    &:last-child {
      border-bottom-right-radius: 0.375rem;
      border-top-right-radius: 0.375rem;
    }
    &:hover {
      background: #e9e6e4;
    }
    + {
      .nav-link {
        margin-left: -1px;
      }
    }
  }
  .nav-link.active {
    background-color: $background-color_2;
    border-color: $border-color_5;
    color: $color_3;
  }
  .show {
    > .nav-link {
      background-color: $background-color_2;
      border-color: $border-color_5;
      color: $color_3;
    }
  }
}
.nav-fill {
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
  > .nav-link {
    flex: 1 1 auto;
    text-align: center;
  }
}
.nav-justified {
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
  > .nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}
.tab-content {
  > .tab-pane {
    display: none;
    text-align: start;
  }
  > .active {
    display: block;
  }
}
.pgn__tabs {
  flex-wrap: nowrap;
  > * {
    position: relative;
  }
  .pgn__tab-notification {
    font-size: 0.75rem;
    min-height: 1rem;
    min-width: 1rem;
    position: absolute;
    top: 0;
    right: 0;
  }
  .pgn__tab_more.nav-link {
    margin-bottom: 0;
    padding: 0;
    .dropdown {
      .dropdown-toggle {
        border: none;
        display: block;
      }
    }
  }
  .pgn__tab_invisible {
    pointer-events: none;
    position: absolute;
    visibility: hidden;
    left: 0;
  }
}
.pgn__tabs.nav-tabs {
  .pgn__tab_more.nav-link {
    .dropdown {
      .dropdown-toggle {
        padding: 0.563rem 1rem;
      }
    }
  }
}
