@use 'base/breakpoints' as *;
@use 'base/functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

.site-header {
  color: var(--mono-100);
  background-color: var(--mono-900);

  .skip-link:not(:focus):not(:active) {
    position: absolute;
    width: 1px;
    height: 1px;
    white-space: nowrap;
    overflow: hidden;
    clip-path: inset(50%);
  }

  > {
    div:nth-of-type(1) {
      background-color: var(--mono-900);
      box-shadow: -75rem 0 var(--mono-900), 75rem 0 var(--mono-900), -150rem 0 var(--mono-900), 150rem 0 var(--mono-900);
    }

    div:nth-of-type(2) {
      background-color: var(--mono-800);
      box-shadow: -75rem 0 var(--mono-800), 75rem 0 var(--mono-800), -150rem 0 var(--mono-800), 150rem 0 var(--mono-800);
    }
  }

  nav {
    position: relative;
    font-family: var(--font-family-custom);
    font-size: rem-calc(16.8);
    padding: rem-calc(12 0);

    li {
      display: inline-flex;
      margin: 0;

      a:not(.btn) {
        display: block;
        text-decoration: none;
        letter-spacing: 0.1116625ex;
        padding: 0.8rem 1rem;;

        &:hover,
        &:focus {
          color: var(--secondary-500);
        }

        &.active {
          background-color: var(--mono-800);
        }
      }

      .btn {
        font-size: rem-calc(16.8);
      }
    }

    &.secondary {

      a:not(.btn) {
        padding: 0.25rem 0 0.25rem;
        border-bottom: 1px solid transparent;
        margin: 0 1.5rem 0 0;

        &[aria-current="page"] {
          box-shadow: none;
          border-bottom-color: var(--mono-100);

          &:hover,
          &:focus {
            border-bottom-color: var(--secondary-500);
            border-bottom-width: 2px;
            margin-bottom: -1px;
          }
        }
      }
    }

    @include breakpoint(medium, max) {

      .wrapper {
        display: flex;
        flex-direction: column-reverse;
      }

      a[href="/"] {
        display: inline-block;
        margin: 0.8rem 0;
      }

      .auth {
        padding: 1rem 0;

        &:not(.logged-in) {
          padding-top: 0;

          li {
            display: block;

            &:first-child {
              display: none;
            }

            &:last-child {
              position: absolute;
              top: 0.75rem;
              right: 0;
            }
          }
        }
      }

      .logged-in {
        position: absolute;
        top: 0;
        right: 0;

        li {
          margin-right: -1rem;
        }
      }

      &.secondary {
        padding: 0.25rem 1rem;
        margin-top: -0.75rem;

        li {
          margin: 0.5rem 0;
        }
      }
    }

    @include breakpoint(medium, min) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      a {
        display: inline-flex;
      }

      .wrapper {
        display: inline-flex;
      }

      li {
        letter-spacing: 0.1ex;

        &:last-child {
          margin-left: 0.5rem;
        }

        a:not(.btn) {
          padding: 1rem;

          &.active {
            box-shadow: 0 -0.75rem var(--mono-800), 0 0.75rem var(--mono-800);
          }
        }
      }

      ul.auth {
        position: relative;
        margin-left: 1rem;

        // vertical line
        &:before {
          left: -0.5rem;
          position: absolute;
          content: "";
          border-left: rem-calc(1) solid var(--mono-700);
          height: 85%;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

aside.system-status {

  & {
    background-color: var(--mono-200);
    font-size: 1rem;
    padding: 1rem;
    color: var(--mono-900);
  }

  p {
    margin-bottom: 0;
  }

  // Ukraine Relief related theme
  // .ukr-relief {

  //   p:before {
  //     content: "";
  //     display: inline-block;
  //     width: 4.6ex;
  //     vertical-align: -0.5ex;
  //     background-color: #fc0;
  //     padding-bottom: 1.15ex;
  //     border: 1px solid #06c;
  //     border-top-width: 1.15ex;
  //     margin-right: 1.15ex;
  //   }
  // }
}
