@use "sass:string";
@use 'functions' as *;
// | -------------------------------------------------------------
// | Breakpoint
// | -------------------------------------------------------------

$breakpoints: (
  "default": 0,
  "small": 24em, // 384px
  "medium": 48em, // 768px
  "large": 75em // 1200px
) !default;

// Modified, via @ https://rimdev.io/making-media-query-mixins-with-sass
// TODO: add a min/max combo
@mixin breakpoint($breakpoint, $direction: min) {
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    
    @if $direction == max {
      @media (max-width: ($breakpoint-value - convert-to-em(1))) {
        @content;
      }
    } @else if $direction == min {
      @media (min-width: $breakpoint-value) {
        @content;
      }
    } 
  
  // If the breakpoint doesn't exist in the map.
  } @else {
    @if ($direction == min or $direction == max) {
      // Convert breakpoint value to string
      $breakpoint-string: #{$breakpoint};
      @if (string.index($breakpoint-string, 'em')) {
        @if $direction == max {
          @media (max-width: ($breakpoint - convert-to-em(1))) {
            @content;
          }
        } @else if $direction == min {
          @media (min-width: $breakpoint) {
            @content;
          }
        } 
      } @else {
        $breakpoint-em: convert-to-em($breakpoint);
        @if $direction == max {
          @media (max-width: ($breakpoint-em - convert-to-em(1))) {
            @content;
          }
        } @else if $direction == min {
          @media (min-width: $breakpoint-em) {
            @content;
          }
        } 
      }
    } @else {
      @error "The direction has to be min or max.";
    }
  }
}
