@use 'base/breakpoints' as *;
@use 'base/functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

$color_1: var(--primary-500);
$color_2: var(--mono-100);
$color_3: #454545;
$color_4: var(--primary-500);
$color_5: var(--green-600);
$color_6: #ffd900;
$color_7: var(--red-600);
$color_8: #e1dddb;
$color_9: var(--green-700);
$color_10: var(--mono-900);
$background-color_1: transparent;
$background-color_2: var(--primary-500);
$background-color_3: var(--mono-100);
$background-color_4: #454545;
$background-color_5: var(--primary-500);
$background-color_6: var(--green-600);
$background-color_7: #ffd900;
$background-color_8: var(--red-600);
$background-color_9: #e1dddb;

.fa-icon-spacing {
  padding: 0 5px 0 0;
}
.btn {
  .btn-icon-before {
    margin-inline-end: 0.5rem;
    margin-left: -0.25em;
  }
  .btn-icon-after {
    margin-inline-start: 0.5rem;
    margin-right: -0.25em;
  }
}
.pgn__menu {
  .pgn__menu-item {
    .btn-icon-before {
      margin-inline-end: 0.5rem;
      margin-left: -0.25em;
    }
    .btn-icon-after {
      margin-inline-start: 0.5rem;
      margin-right: -0.25em;
    }
  }
}
.pgn__stateful-btn-icon {
  margin-inline-end: 0.5em;
  margin-left: -0.25em;
}
.btn-icon {
  border: none;
  border-radius: 50%;
  display: inline-flex;
  height: 2.75rem;
  justify-content: center;
  padding: 0;
  width: 2.75rem;
  .btn-icon__icon-container {
    align-self: center;
    display: inline-flex;
    .pgn__icon.btn-icon__icon {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
  .btn-icon__icon {
    height: 1em;
    width: 1em;
  }
  &:disabled {
    opacity: 0.65;
    pointer-events: none;
  }
}
.btn-icon.btn-icon-sm {
  height: 2.25rem;
  width: 2.25rem;
}
.btn-icon.btn-icon-inline {
  height: 1.6556em;
  padding: 1em;
  vertical-align: middle;
  width: 1.6556em;
  .btn-icon__icon-container {
    align-items: center;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
}
.btn-icon.disabled {
  opacity: 0.65;
  pointer-events: none;
}
.btn-icon.btn-icon-primary {
  background-color: transparent;
  color: $color_1;
  &:active {
    background-color: $background-color_2;
    color: $color_2;
  }
  &:hover {
    background-color: $background-color_2;
    color: $color_2;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--primary-500);
    color: $color_1;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-primary.active {
  background-color: $background-color_2;
  color: $color_2;
}
.btn-icon.btn-icon-primary.hover {
  background-color: $background-color_2;
  color: $color_2;
}
.btn-icon.btn-icon-primary.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--primary-500);
  color: $color_1;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-primary {
  background-color: transparent;
  color: $color_2;
  &:active {
    background-color: $background-color_3;
    color: $color_1;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_1;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--mono-100);
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-primary.active {
  background-color: $background-color_3;
  color: $color_1;
}
.btn-icon.btn-icon-inverse-primary.hover {
  background-color: $background-color_3;
  color: $color_1;
}
.btn-icon.btn-icon-inverse-primary.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--mono-100);
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-primary-active {
  background-color: $background-color_2;
  color: $color_2;
  &:active {
    background-color: $background-color_2;
    color: $color_2;
  }
  &:hover {
    background-color: $background-color_2;
    color: $color_2;
  }
  &:focus {
    background-color: $background-color_2;
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-primary-active.active {
  background-color: $background-color_2;
  color: $color_2;
}
.btn-icon.btn-icon-primary-active.hover {
  background-color: $background-color_2;
  color: $color_2;
}
.btn-icon.btn-icon-primary-active.focus {
  background-color: $background-color_2;
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-primary-active {
  background-color: $background-color_3;
  color: $color_1;
  &:active {
    background-color: $background-color_3;
    color: $color_1;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_1;
  }
  &:focus {
    background-color: $background-color_3;
    color: $color_1;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-primary-active.active {
  background-color: $background-color_3;
  color: $color_1;
}
.btn-icon.btn-icon-inverse-primary-active.hover {
  background-color: $background-color_3;
  color: $color_1;
}
.btn-icon.btn-icon-inverse-primary-active.focus {
  background-color: $background-color_3;
  color: $color_1;
  outline: 2px transparent;
}
.btn-icon.btn-icon-secondary {
  background-color: transparent;
  color: $color_3;
  &:active {
    background-color: $background-color_4;
    color: $color_2;
  }
  &:hover {
    background-color: $background-color_4;
    color: $color_2;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #454545;
    color: $color_3;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-secondary.active {
  background-color: $background-color_4;
  color: $color_2;
}
.btn-icon.btn-icon-secondary.hover {
  background-color: $background-color_4;
  color: $color_2;
}
.btn-icon.btn-icon-secondary.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #454545;
  color: $color_3;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-secondary {
  background-color: transparent;
  color: $color_2;
  &:active {
    background-color: $background-color_3;
    color: $color_3;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_3;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--mono-100);
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-secondary.active {
  background-color: $background-color_3;
  color: $color_3;
}
.btn-icon.btn-icon-inverse-secondary.hover {
  background-color: $background-color_3;
  color: $color_3;
}
.btn-icon.btn-icon-inverse-secondary.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--mono-100);
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-secondary-active {
  background-color: $background-color_4;
  color: $color_2;
  &:active {
    background-color: $background-color_4;
    color: $color_2;
  }
  &:hover {
    background-color: $background-color_4;
    color: $color_2;
  }
  &:focus {
    background-color: $background-color_4;
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-secondary-active.active {
  background-color: $background-color_4;
  color: $color_2;
}
.btn-icon.btn-icon-secondary-active.hover {
  background-color: $background-color_4;
  color: $color_2;
}
.btn-icon.btn-icon-secondary-active.focus {
  background-color: $background-color_4;
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-secondary-active {
  background-color: $background-color_3;
  color: $color_3;
  &:active {
    background-color: $background-color_3;
    color: $color_3;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_3;
  }
  &:focus {
    background-color: $background-color_3;
    color: $color_3;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-secondary-active.active {
  background-color: $background-color_3;
  color: $color_3;
}
.btn-icon.btn-icon-inverse-secondary-active.hover {
  background-color: $background-color_3;
  color: $color_3;
}
.btn-icon.btn-icon-inverse-secondary-active.focus {
  background-color: $background-color_3;
  color: $color_3;
  outline: 2px transparent;
}
.btn-icon.btn-icon-brand {
  background-color: transparent;
  color: $color_4;
  &:active {
    background-color: $background-color_5;
    color: $color_2;
  }
  &:hover {
    background-color: $background-color_5;
    color: $color_2;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--primary-500);
    color: $color_4;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-brand.active {
  background-color: $background-color_5;
  color: $color_2;
}
.btn-icon.btn-icon-brand.hover {
  background-color: $background-color_5;
  color: $color_2;
}
.btn-icon.btn-icon-brand.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--primary-500);
  color: $color_4;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-brand {
  background-color: transparent;
  color: $color_2;
  &:active {
    background-color: $background-color_3;
    color: $color_4;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_4;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--mono-100);
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-brand.active {
  background-color: $background-color_3;
  color: $color_4;
}
.btn-icon.btn-icon-inverse-brand.hover {
  background-color: $background-color_3;
  color: $color_4;
}
.btn-icon.btn-icon-inverse-brand.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--mono-100);
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-brand-active {
  background-color: $background-color_5;
  color: $color_2;
  &:active {
    background-color: $background-color_5;
    color: $color_2;
  }
  &:hover {
    background-color: $background-color_5;
    color: $color_2;
  }
  &:focus {
    background-color: $background-color_5;
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-brand-active.active {
  background-color: $background-color_5;
  color: $color_2;
}
.btn-icon.btn-icon-brand-active.hover {
  background-color: $background-color_5;
  color: $color_2;
}
.btn-icon.btn-icon-brand-active.focus {
  background-color: $background-color_5;
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-brand-active {
  background-color: $background-color_3;
  color: $color_4;
  &:active {
    background-color: $background-color_3;
    color: $color_4;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_4;
  }
  &:focus {
    background-color: $background-color_3;
    color: $color_4;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-brand-active.active {
  background-color: $background-color_3;
  color: $color_4;
}
.btn-icon.btn-icon-inverse-brand-active.hover {
  background-color: $background-color_3;
  color: $color_4;
}
.btn-icon.btn-icon-inverse-brand-active.focus {
  background-color: $background-color_3;
  color: $color_4;
  outline: 2px transparent;
}
.btn-icon.btn-icon-success {
  background-color: transparent;
  color: $color_5;
  &:active {
    background-color: $background-color_6;
    color: $color_2;
  }
  &:hover {
    background-color: $background-color_6;
    color: $color_2;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--green-600);
    color: $color_5;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-success.active {
  background-color: $background-color_6;
  color: $color_2;
}
.btn-icon.btn-icon-success.hover {
  background-color: $background-color_6;
  color: $color_2;
}
.btn-icon.btn-icon-success.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--green-600);
  color: $color_5;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-success {
  background-color: transparent;
  color: $color_2;
  &:active {
    background-color: $background-color_3;
    color: $color_5;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_5;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--mono-100);
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-success.active {
  background-color: $background-color_3;
  color: $color_5;
}
.btn-icon.btn-icon-inverse-success.hover {
  background-color: $background-color_3;
  color: $color_5;
}
.btn-icon.btn-icon-inverse-success.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--mono-100);
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-success-active {
  background-color: $background-color_6;
  color: $color_2;
  &:active {
    background-color: $background-color_6;
    color: $color_2;
  }
  &:hover {
    background-color: $background-color_6;
    color: $color_2;
  }
  &:focus {
    background-color: $background-color_6;
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-success-active.active {
  background-color: $background-color_6;
  color: $color_2;
}
.btn-icon.btn-icon-success-active.hover {
  background-color: $background-color_6;
  color: $color_2;
}
.btn-icon.btn-icon-success-active.focus {
  background-color: $background-color_6;
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-success-active {
  background-color: $background-color_3;
  color: $color_5;
  &:active {
    background-color: $background-color_3;
    color: $color_5;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_5;
  }
  &:focus {
    background-color: $background-color_3;
    color: $color_5;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-success-active.active {
  background-color: $background-color_3;
  color: $color_5;
}
.btn-icon.btn-icon-inverse-success-active.hover {
  background-color: $background-color_3;
  color: $color_5;
}
.btn-icon.btn-icon-inverse-success-active.focus {
  background-color: $background-color_3;
  color: $color_5;
  outline: 2px transparent;
}
.btn-icon.btn-icon-warning {
  background-color: transparent;
  color: $color_6;
  &:active {
    background-color: $background-color_7;
    color: $color_2;
  }
  &:hover {
    background-color: $background-color_7;
    color: $color_2;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffd900;
    color: $color_6;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-warning.active {
  background-color: $background-color_7;
  color: $color_2;
}
.btn-icon.btn-icon-warning.hover {
  background-color: $background-color_7;
  color: $color_2;
}
.btn-icon.btn-icon-warning.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #ffd900;
  color: $color_6;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-warning {
  background-color: transparent;
  color: $color_2;
  &:active {
    background-color: $background-color_3;
    color: $color_6;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_6;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--mono-100);
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-warning.active {
  background-color: $background-color_3;
  color: $color_6;
}
.btn-icon.btn-icon-inverse-warning.hover {
  background-color: $background-color_3;
  color: $color_6;
}
.btn-icon.btn-icon-inverse-warning.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--mono-100);
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-warning-active {
  background-color: $background-color_7;
  color: $color_2;
  &:active {
    background-color: $background-color_7;
    color: $color_2;
  }
  &:hover {
    background-color: $background-color_7;
    color: $color_2;
  }
  &:focus {
    background-color: $background-color_7;
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-warning-active.active {
  background-color: $background-color_7;
  color: $color_2;
}
.btn-icon.btn-icon-warning-active.hover {
  background-color: $background-color_7;
  color: $color_2;
}
.btn-icon.btn-icon-warning-active.focus {
  background-color: $background-color_7;
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-warning-active {
  background-color: $background-color_3;
  color: $color_6;
  &:active {
    background-color: $background-color_3;
    color: $color_6;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_6;
  }
  &:focus {
    background-color: $background-color_3;
    color: $color_6;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-warning-active.active {
  background-color: $background-color_3;
  color: $color_6;
}
.btn-icon.btn-icon-inverse-warning-active.hover {
  background-color: $background-color_3;
  color: $color_6;
}
.btn-icon.btn-icon-inverse-warning-active.focus {
  background-color: $background-color_3;
  color: $color_6;
  outline: 2px transparent;
}
.btn-icon.btn-icon-danger {
  background-color: transparent;
  color: $color_7;
  &:active {
    background-color: $background-color_8;
    color: $color_2;
  }
  &:hover {
    background-color: $background-color_8;
    color: $color_2;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--red-600);
    color: $color_7;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-danger.active {
  background-color: $background-color_8;
  color: $color_2;
}
.btn-icon.btn-icon-danger.hover {
  background-color: $background-color_8;
  color: $color_2;
}
.btn-icon.btn-icon-danger.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--red-600);
  color: $color_7;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-danger {
  background-color: transparent;
  color: $color_2;
  &:active {
    background-color: $background-color_3;
    color: $color_7;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_7;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--mono-100);
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-danger.active {
  background-color: $background-color_3;
  color: $color_7;
}
.btn-icon.btn-icon-inverse-danger.hover {
  background-color: $background-color_3;
  color: $color_7;
}
.btn-icon.btn-icon-inverse-danger.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--mono-100);
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-danger-active {
  background-color: $background-color_8;
  color: $color_2;
  &:active {
    background-color: $background-color_8;
    color: $color_2;
  }
  &:hover {
    background-color: $background-color_8;
    color: $color_2;
  }
  &:focus {
    background-color: $background-color_8;
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-danger-active.active {
  background-color: $background-color_8;
  color: $color_2;
}
.btn-icon.btn-icon-danger-active.hover {
  background-color: $background-color_8;
  color: $color_2;
}
.btn-icon.btn-icon-danger-active.focus {
  background-color: $background-color_8;
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-danger-active {
  background-color: $background-color_3;
  color: $color_7;
  &:active {
    background-color: $background-color_3;
    color: $color_7;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_7;
  }
  &:focus {
    background-color: $background-color_3;
    color: $color_7;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-danger-active.active {
  background-color: $background-color_3;
  color: $color_7;
}
.btn-icon.btn-icon-inverse-danger-active.hover {
  background-color: $background-color_3;
  color: $color_7;
}
.btn-icon.btn-icon-inverse-danger-active.focus {
  background-color: $background-color_3;
  color: $color_7;
  outline: 2px transparent;
}
.btn-icon.btn-icon-light {
  background-color: transparent;
  color: $color_8;
  &:active {
    background-color: $background-color_9;
    color: $color_2;
  }
  &:hover {
    background-color: $background-color_9;
    color: $color_2;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #e1dddb;
    color: $color_8;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-light.active {
  background-color: $background-color_9;
  color: $color_2;
}
.btn-icon.btn-icon-light.hover {
  background-color: $background-color_9;
  color: $color_2;
}
.btn-icon.btn-icon-light.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #e1dddb;
  color: $color_8;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-light {
  background-color: transparent;
  color: $color_2;
  &:active {
    background-color: $background-color_3;
    color: $color_8;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_8;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--mono-100);
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-light.active {
  background-color: $background-color_3;
  color: $color_8;
}
.btn-icon.btn-icon-inverse-light.hover {
  background-color: $background-color_3;
  color: $color_8;
}
.btn-icon.btn-icon-inverse-light.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--mono-100);
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-light-active {
  background-color: $background-color_9;
  color: $color_2;
  &:active {
    background-color: $background-color_9;
    color: $color_2;
  }
  &:hover {
    background-color: $background-color_9;
    color: $color_2;
  }
  &:focus {
    background-color: $background-color_9;
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-light-active.active {
  background-color: $background-color_9;
  color: $color_2;
}
.btn-icon.btn-icon-light-active.hover {
  background-color: $background-color_9;
  color: $color_2;
}
.btn-icon.btn-icon-light-active.focus {
  background-color: $background-color_9;
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-light-active {
  background-color: $background-color_3;
  color: $color_8;
  &:active {
    background-color: $background-color_3;
    color: $color_8;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_8;
  }
  &:focus {
    background-color: $background-color_3;
    color: $color_8;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-light-active.active {
  background-color: $background-color_3;
  color: $color_8;
}
.btn-icon.btn-icon-inverse-light-active.hover {
  background-color: $background-color_3;
  color: $color_8;
}
.btn-icon.btn-icon-inverse-light-active.focus {
  background-color: $background-color_3;
  color: $color_8;
  outline: 2px transparent;
}
.btn-icon.btn-icon-dark {
  background-color: transparent;
  color: $color_9;
  &:active {
    background-color: var(--green-700);
    color: $color_2;
  }
  &:hover {
    background-color: var(--green-700);
    color: $color_2;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--green-700);
    color: $color_9;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-dark.active {
  background-color: var(--green-700);
  color: $color_2;
}
.btn-icon.btn-icon-dark.hover {
  background-color: var(--green-700);
  color: $color_2;
}
.btn-icon.btn-icon-dark.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--green-700);
  color: $color_9;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-dark {
  background-color: transparent;
  color: $color_2;
  &:active {
    background-color: $background-color_3;
    color: $color_9;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_9;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--mono-100);
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-dark.active {
  background-color: $background-color_3;
  color: $color_9;
}
.btn-icon.btn-icon-inverse-dark.hover {
  background-color: $background-color_3;
  color: $color_9;
}
.btn-icon.btn-icon-inverse-dark.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--mono-100);
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-dark-active {
  background-color: var(--green-700);
  color: $color_2;
  &:active {
    background-color: var(--green-700);
    color: $color_2;
  }
  &:hover {
    background-color: var(--green-700);
    color: $color_2;
  }
  &:focus {
    background-color: var(--green-700);
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-dark-active.active {
  background-color: var(--green-700);
  color: $color_2;
}
.btn-icon.btn-icon-dark-active.hover {
  background-color: var(--green-700);
  color: $color_2;
}
.btn-icon.btn-icon-dark-active.focus {
  background-color: var(--green-700);
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-dark-active {
  background-color: $background-color_3;
  color: $color_9;
  &:active {
    background-color: $background-color_3;
    color: $color_9;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_9;
  }
  &:focus {
    background-color: $background-color_3;
    color: $color_9;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-dark-active.active {
  background-color: $background-color_3;
  color: $color_9;
}
.btn-icon.btn-icon-inverse-dark-active.hover {
  background-color: $background-color_3;
  color: $color_9;
}
.btn-icon.btn-icon-inverse-dark-active.focus {
  background-color: $background-color_3;
  color: $color_9;
  outline: 2px transparent;
}
.btn-icon.btn-icon-black {
  background-color: transparent;
  color: $color_10;
  &:active {
    background-color: var(--mono-900);
    color: $color_2;
  }
  &:hover {
    background-color: var(--mono-900);
    color: $color_2;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--mono-900);
    color: $color_10;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-black.active {
  background-color: var(--mono-900);
  color: $color_2;
}
.btn-icon.btn-icon-black.hover {
  background-color: var(--mono-900);
  color: $color_2;
}
.btn-icon.btn-icon-black.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--mono-900);
  color: $color_10;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-black {
  background-color: transparent;
  color: $color_2;
  &:active {
    background-color: $background-color_3;
    color: $color_10;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_10;
  }
  &:focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px var(--mono-100);
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-black.active {
  background-color: $background-color_3;
  color: $color_10;
}
.btn-icon.btn-icon-inverse-black.hover {
  background-color: $background-color_3;
  color: $color_10;
}
.btn-icon.btn-icon-inverse-black.focus {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--mono-100);
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-black-active {
  background-color: var(--mono-900);
  color: $color_2;
  &:active {
    background-color: var(--mono-900);
    color: $color_2;
  }
  &:hover {
    background-color: var(--mono-900);
    color: $color_2;
  }
  &:focus {
    background-color: var(--mono-900);
    color: $color_2;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-black-active.active {
  background-color: var(--mono-900);
  color: $color_2;
}
.btn-icon.btn-icon-black-active.hover {
  background-color: var(--mono-900);
  color: $color_2;
}
.btn-icon.btn-icon-black-active.focus {
  background-color: var(--mono-900);
  color: $color_2;
  outline: 2px transparent;
}
.btn-icon.btn-icon-inverse-black-active {
  background-color: $background-color_3;
  color: $color_10;
  &:active {
    background-color: $background-color_3;
    color: $color_10;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_10;
  }
  &:focus {
    background-color: $background-color_3;
    color: $color_10;
    outline: 2px transparent;
  }
}
.btn-icon.btn-icon-inverse-black-active.active {
  background-color: $background-color_3;
  color: $color_10;
}
.btn-icon.btn-icon-inverse-black-active.hover {
  background-color: $background-color_3;
  color: $color_10;
}
.btn-icon.btn-icon-inverse-black-active.focus {
  background-color: $background-color_3;
  color: $color_10;
  outline: 2px transparent;
}
.pgn__icon-button-toggle__container {
  display: flex;
  .btn-icon {
    + {
      .btn-icon {
        margin-inline-start: 0.25rem;
      }
    }
  }
}
.pgn__icon {
  display: block;
  flex-shrink: 0;
  height: 1.5rem;
  position: relative;
  width: 1.5rem;
  svg {
    display: block;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
  }
}
.pgn__icon.pgn__icon__inline {
  height: 0.8em;
  width: 0.8em;
}
.pgn__icon.pgn__icon__xs {
  height: 1rem;
  width: 1rem;
}
.pgn__icon.pgn__icon__sm {
  height: 1.25rem;
  width: 1.25rem;
}
.pgn__icon.pgn__icon__md {
  height: 1.5rem;
  width: 1.5rem;
}
.pgn__icon.pgn__icon__lg {
  height: 1.75rem;
  width: 1.75rem;
}
