@use 'base/breakpoints' as *;
@use 'base/functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}
@media (min-width: 768px) {
  .d-md-flex {
      display: flex !important;
  }
}

.col-auto {
  flex: 0 0 auto;
  max-width: 100%;
  width: auto;
}
