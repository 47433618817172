// | -------------------------------------------------------------
// | Typography
// | -------------------------------------------------------------
@use 'breakpoints' as *;

body {
  font-family: var(--font-family-default);
  // text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-rendering: optimizeLegibility;
  font-synthesis: none;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  // TODO: although using these smooths stuff out, it makes the rendering different from our current Gymnasium site.
  // -moz-osx-font-smoothing: grayscale;
  // -webkit-font-smoothing: antialiased;
}

a:not(.btn) {
  color: var(--action-color);
  text-decoration: underline;

  &:visited {
    color: var(--action-color);
  }

  &:hover,
  &:focus {
    color: var(--action-color-state);
    text-decoration: none;
  }
}

:where(main) a {
  word-wrap: break-word;
  word-break: break-word;
}

:where(h1, h2) {
  font-family: var(--font-family-special);
}

h1,
h2 {
  font-weight: 900;
}

h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1, .h1 {
  font-size: 2.25rem;
  line-height: 1.15;
  text-transform: uppercase;

  @include breakpoint(medium) {
    font-size: 3rem;
  }
}

h2, .h2 {
  font-size: 1.875rem;
  line-height: 1.15;

  @include breakpoint(medium) {
    font-size: 2.25rem;
    line-height: 3.375rem;
  }
}

h3, .h3 {
  font-size: 1.5rem;
  line-height: 1.15;

  @include breakpoint(medium) {
    font-size: 1.875rem;
    line-height: 2.8125rem;
  }
}

h4, .h4 {
  font-size: 1.25rem;
  line-height: 1.15;

  @include breakpoint(medium) {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

h5, .h5 {
  font-size: 1.125rem;
  line-height: 1.15;

  @include breakpoint(medium) {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
}

h6, .h6 {
  font-size: 1rem;

  @include breakpoint(medium) {
    font-size: 1.125rem;
    line-height: 1.15;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family-custom);
  margin-bottom: 1rem;
}

p {
  text-wrap: pretty;
}

p + *,
* + p {
  margin-top: 1rem;
}

b,
strong {
  font-weight: bolder;
}

.caps {
  text-transform: uppercase;
}
