// Regular
@font-face {
  font-family: "bg";
  src:
    local("Brandon Grotesque Regular"),
    local("BrandonGrotesque-Regular"),
    url("https://gym-prod.netlify.app/fonts/bg-rg.woff2")
      format("woff2"),
    url("https://gym-prod.netlify.app/fonts/bg-rg.woff")
      format("woff");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

// Regular Italic
@font-face {
  font-family: "bg";
  src:
    local("Brandon Grotesque Regular Italic"),
    local("BrandonGrotesque-RegularItalic"),
    url("https://gym-prod.netlify.app/fonts/bg-rg-it.woff2")
      format("woff2"),
    url("https://gym-prod.netlify.app/fonts/bg-rg-it.woff")
      format("woff");
  font-style: italic;
  font-weight: normal;
  font-display: swap;
}

// Bold
@font-face {
  font-family: "bg";
  src:
    local("Brandon Grotesque Bold"),
    local("BrandonGrotesque-Bold"),
    url("https://gym-prod.netlify.app/fonts/bg-bd.woff2")
      format("woff2"),
    url("https://gym-prod.netlify.app/fonts/bg-bd.woff")
      format("woff");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

// Bold Italic
@font-face {
  font-family: "bg";
  src:
    local("Brandon Grotesque Bold Italic"),
    local("BrandonGrotesque-BoldItalic"),
    url("https://gym-prod.netlify.app/fonts/bg-bd-it.woff2")
      format("woff2"),
    url("https://gym-prod.netlify.app/fonts/bg-bd-it.woff")
      format("woff");
  font-style: italic;
  font-weight: bold;
  font-display: swap;
}

// Black
@font-face {
  font-family: "bg";
  src:
    local("Brandon Grotesque Black"),
    local("BrandonGrotesque-Black"),
    url("https://gym-prod.netlify.app/fonts/bg-bl.woff2")
      format("woff2"),
    url("https://gym-prod.netlify.app/fonts/bg-bl.woff")
      format("woff");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

// Black Italic
@font-face {
  font-family: "bg";
  src:
    local("Brandon Grotesque Black Italic"),
    local("BrandonGrotesque-BlackItalic"),
    url("https://gym-prod.netlify.app/fonts/bg-bl-it.woff2")
      format("woff2"),
    url("https://gym-prod.netlify.app/fonts/bg-bl-it.woff")
      format("woff");
  font-style: italic;
  font-weight: 900;
  font-display: swap;
}