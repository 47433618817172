@use "sass:map";
@use 'base/breakpoints' as *;
@use 'base/functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

main {
  margin: 0 auto;
  max-width: convert-to-rem(map.get($breakpoints, 'large'));
  padding-top: 1.4rem;
}
