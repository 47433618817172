// | -------------------------------------------------------------
// | Button
// | -------------------------------------------------------------
@use 'functions' as *;
@use 'mixins' as *;

a, summary {
  cursor: pointer;
}

[disabled], .disabled {
  pointer-events: none;
  cursor: not-allowed;
}

[role="button"] {
  cursor: pointer;
}

.btn {
  background-color: var(--primary-500);
  border: rem-calc(3) solid var(--primary-500);
  border-radius: var(--global-button-radius);
  color: var(--mono-100);
  display: inline-flex;
  font-family: var(--font-family-custom);
  font-size: rem-calc(20);
  font-weight: bold;
  line-height: 1;
  padding: rem-calc(16.8);
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

  &.disabled,
  &[disabled] {
    background-color: var(--mono-200);
    border-color: var(--mono-200);
    color: var(--mono-900);
  }

  &:hover,
  &:focus {
    color: var(--mono-100);
    background-color: var(--primary-600);
    border-color: var(--primary-600);
    text-decoration: none;
  }

  &.secondary {
    background-color: var(--secondary-500);
    border-color: (var(--secondary-500));

    // &:hover,
    // &:focus {
    //   background-color: var(--secondary-400);
    //   border-color: var(--secondary-400);
    // }
  }

  &.mono {
    background-color: var(--mono-500);
    border-color: (var(--mono-500));
  }

  &.color-info {
    background-color: var(--info-300);
    border-color: (var(--info-300));

    // &:hover,
    // &:focus {
    //   background-color: var(--info-200);
    //   border-color: var(--info-200);
    // }
  }

  &.color-success {
    background-color: var(--success-400);
    border-color: (var(--success-400));

    // &:hover,
    // &:focus {
    //   background-color: var(--success-300);
    //   border-color: var(--success-300);
    // }
  }

  &.color-warning {
    background-color: var(--warning-400);
    border-color: (var(--warning-400));

    // &:hover,
    // &:focus {
    //   background-color: var(--warning-300);
    //   border-color: var(--warning-300);
    // }
  }

  &.color-error {
    background-color: var(--error-300);
    border-color: (var(--error-300));

    // &:hover,
    // &:focus {
    //   background-color: var(--error-200);
    //   border-color: var(--error-200);
    // }
  }

  &.size-tiny {
    padding: 0.125rem 0.25rem;
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  &.size-small {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }

  &.size-large {
    padding: 0.75rem 1rem;
    font-size: 1.125rem;
    line-height: 1.6875rem;
  }

  &.size-huge {
    padding: 1rem 2rem;
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  &.behavior-full {
    display: block;
    width: 100%;
  }

  &.type-secondary {
    background-color: transparent;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  &.has-icon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &-link {
    background-color: transparent;
    font-weight: 400;
    text-decoration: underline;
    border: 0;
    font-size: rem-calc(16);
    font-family: inherit;
    color: inherit;

    &:focus,
    &:hover,
    &:active,
    &.active {
      background-color: transparent;
      color: var(--primary-500);
      text-decoration: underline;
    }
  }

  &-inline {
    font-size: inherit;
    line-height: calc(1.5556em - 2px);
    padding: 0 .25em;
    vertical-align: bottom;
  }

  &:not(:only-of-type) {
    margin-right: 0.5rem;
  }
}
