@use "sass:map";
@use 'base/breakpoints' as *;
@use 'base/functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

$color_1: #454545;
$color_2: #212529;
$color_3: #16181b;
$color_5: #707070;
$color_6: #082644;
$color_7: #b4b1af;
$background-color_3: #f0eeed;
$background-color_5: #939f97;
$background-color_6: #c9cfcb;
$background-color_7: inherit;
$border-color_1: #c9cfcb;

.dropdown {
  position: relative;
}
.dropleft {
  position: relative;
  .dropdown-menu {
    margin-top: 0;
    top: 0;
    left: auto;
    margin-right: 0.125rem;
    right: 100%;
    left: 100%;
    margin-left: 0.125rem;
    right: auto;
  }
  .dropdown-toggle {
    &::after {
      content: "";
      display: inline-block;
      vertical-align: 0.255em;
      margin-left: 0.255em;
      margin-right: 0.255em;
      display: none;
    }
    &::before {
      border-bottom: 0.3em solid transparent;
      border-top: 0.3em solid transparent;
      content: "";
      display: inline-block;
      vertical-align: 0.255em;
      border-right: 0.3em solid;
      margin-right: 0.255em;
      border-left: 0.3em solid;
      margin-left: 0.255em;
      vertical-align: 0;
    }
    &:empty {
      &::after {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
.dropright {
  position: relative;
  .dropdown-menu {
    margin-top: 0;
    top: 0;
    left: 100%;
    margin-left: 0.125rem;
    right: auto;
    left: auto;
    margin-right: 0.125rem;
    right: 100%;
  }
  .dropdown-toggle {
    &::after {
      border-bottom: 0.3em solid transparent;
      border-top: 0.3em solid transparent;
      content: "";
      display: inline-block;
      vertical-align: 0.255em;
      border-left: 0.3em solid;
      border-right: 0;
      margin-left: 0.255em;
      border-left: 0;
      border-right: 0.3em solid;
      margin-right: 0.255em;
      vertical-align: 0;
    }
    &:empty {
      &::after {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
.dropup {
  position: relative;
  .dropdown-menu {
    bottom: 100%;
    margin-bottom: 0.125rem;
    margin-top: 0;
    top: auto;
  }
  .dropdown-toggle {
    &::after {
      border-bottom: 0.3em solid;
      border-left: 0.3em solid transparent;
      border-right: 0.3em solid transparent;
      border-top: 0;
      content: "";
      display: inline-block;
      vertical-align: 0.255em;
      margin-left: 0.255em;
      margin-right: 0.255em;
    }
    &:empty {
      &::after {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
.dropdown-toggle {
  white-space: nowrap;
  &::after {
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    border-top: 0.3em solid;
    display: inline-block;
    vertical-align: 0.255em;
    margin-left: 0.255em;
    margin-right: 0.255em;
    border: 0;
    border-style: solid;
    content: "";
    height: 0.45rem;
    margin-inline-start: 0.5em;
    position: relative;
    top: -0.05rem;
    width: 0.45rem;
    border-width: 0.15rem 0.15rem 0 0;
    transform: rotate(135deg);
    border-width: 0.15rem 0 0 0.15rem;
    transform: rotate(-135deg);
  }
  &:empty {
    &::after {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
.dropdown-menu {
  background-clip: padding-box;
  background-color: var(--mono-100);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem;
  color: $color_1;
  display: none;
  font-size: 1.125rem;
  list-style: none;
  margin: 0.125rem 0 0;
  min-width: 18rem;
  padding: 0.5rem 0;
  position: absolute;
  top: 100%;
  z-index: 1000;
  float: left;
  left: 0;
  text-align: left;
  float: right;
  right: 0;
  text-align: right;
}
.dropdown-menu-left {
  left: 0;
  right: auto;
  left: auto;
  right: 0;
}
.dropdown-menu-right {
  left: auto;
  right: 0;
  left: 0;
  right: auto;
}
.dropdown-menu[x-placement^="bottom"] {
  bottom: auto;
  right: auto;
  left: auto;
}
.dropdown-menu[x-placement^="left"] {
  bottom: auto;
  right: auto;
  left: auto;
}
.dropdown-menu[x-placement^="right"] {
  bottom: auto;
  right: auto;
  left: auto;
}
.dropdown-menu[x-placement^="top"] {
  bottom: auto;
  right: auto;
  left: auto;
}
.dropdown-divider {
  border-top: 1px solid #ebebeb;
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
}
.dropdown-item {
  background-color: transparent;
  border: 0;
  clear: both;
  color: $color_2;
  display: block;
  font-weight: 400;
  padding: 0.25rem 1rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
  text-align: right;
  &:focus {
    background-color: $background-color_3;
    color: $color_3;
    text-decoration: none;
  }
  &:hover {
    background-color: $background-color_3;
    color: $color_3;
    text-decoration: none;
  }
  &:active {
    background-color: var(--primary-500);
    color: var(--mono-100);
    text-decoration: none;
  }
  &:disabled {
    background-color: transparent;
    color: $color_5;
    pointer-events: none;
  }
}
.dropdown-item.active {
  background-color: var(--primary-500);
  color: var(--mono-100);
  text-decoration: none;
}
.dropdown-item.disabled {
  background-color: transparent;
  color: $color_5;
  pointer-events: none;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  color: $color_5;
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  white-space: nowrap;
}
.dropdown-item-text {
  color: $color_2;
  display: block;
  padding: 0.25rem 1rem;
}
.pgn__dropdown-toggle-iconbutton {
  white-space: nowrap;
}
.pgn__dropdown-item {
  align-items: center;
  display: flex;
  .pgn__dropdown-item__icon-before {
    margin-inline-end: 0.5rem;
    margin-left: -0.25em;
    margin-right: -0.25em;
    margin-left: 0.5rem;
    margin-right: -0.25em;
  }
  .pgn__dropdown-item__icon-after {
    margin-inline-start: 0.5rem;
    margin-right: -0.25em;
    margin-left: -0.25em;
    margin-left: -0.25em;
    margin-right: 0.5rem;
  }
}
.pgn__dropdown-dark {
  .dropdown-menu {
    background-color: $background-color_5;
    color: var(--mono-100);
    .pgn__dropdown-item {
      color: var(--mono-100);
      font-weight: 400;
      &:hover {
        background-color: $background-color_6;
      }
      &:focus {
        background-color: $background-color_7;
        border: 2px solid var(--mono-100);
      }
    }
    .pgn__dropdown-item.active {
      background-color: $background-color_3;
      color: $color_6;
      font-weight: 700;
    }
    .dropdown-header {
      color: $color_7;
    }
    .dropdown-divider {
      border-color: $border-color_1;
    }
  }
}
@include breakpoint(576) {
  .dropdown-menu-sm-left {
    left: 0;
    right: auto;
    left: auto;
    right: 0;
  }
  .dropdown-menu-sm-right {
    left: auto;
    right: 0;
    left: 0;
    right: auto;
  }
}
@include breakpoint(medium) {
  .dropdown-menu-md-left {
    left: 0;
    right: auto;
    left: auto;
    right: 0;
  }
  .dropdown-menu-md-right {
    left: auto;
    right: 0;
    left: 0;
    right: auto;
  }
}
@include breakpoint(992) {
  .dropdown-menu-lg-left {
    left: 0;
    right: auto;
    left: auto;
    right: 0;
  }
  .dropdown-menu-lg-right {
    left: auto;
    right: 0;
    left: 0;
    right: auto;
  }
}


@include breakpoint(large) {
  .dropdown-menu-xl-left {
    left: 0;
    right: auto;
    left: auto;
    right: 0;
  }
  .dropdown-menu-xl-right {
    left: auto;
    right: 0;
    left: 0;
    right: auto;
  }
}
@include breakpoint(1400) {
  .dropdown-menu-xxl-left {
    left: 0;
    right: auto;
    left: auto;
    right: 0;
  }
  .dropdown-menu-xxl-right {
    left: auto;
    right: 0;
    left: 0;
    right: auto;
  }
}
