@use 'base/breakpoints' as *;
@use 'base/functions' as *;
@use 'base/mixins' as *;
@use 'base/fonts' as *;

.site-footer {
  background-color: var(--mono-900);
  color: var(--mono-100);
  padding: 2.6rem 0;
  border-top: 0.625rem solid var(--secondary-500);

  p {
    letter-spacing: 0.05ex;

    &:only-of-type {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
  }

  .main {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, auto));
    gap: 2rem 2.8rem;
    justify-content: start;
  }

  .header {
    grid-column: 1 / -1;

    img {
      max-width: none;
    }

    :first-child + p {
      margin-top: 2rem;
    }
  }

  @include breakpoint(large, min) {
    .main {
      grid-auto-flow: column;
      grid-template-columns: 1fr;
      column-gap: 4.2rem;
    }
    .header {
      max-width: 16rem;

      p {
        text-wrap: pretty;
      }
    }
    #resources {
      max-width: 8rem;
    }
  }

  nav {
    h2 {
      font-family: var(--font-family-custom);
      font-size: 1rem;
      line-height: 1.35;
      text-transform: uppercase;
      letter-spacing: 0.2ex;
      margin-top: 0.5rem;
    }
    ul {
      padding-top: 0.2rem;
      border-top: 1px solid var(--mono-700);
    }
    li {
      font-size: 1.05rem;
      letter-spacing: 0.1ex;

      &:not(first-child) {
        margin-top: 0.6rem;
      }

      img {
        width: 1.25rem;
        vertical-align: -0.25rem;
        margin-right: 0.5rem;
      }
    }
    a {
      font-family: var(--font-family-custom);

      &:hover,
      &:focus {
        color: var(--secondary-500);
      }
    }
  }

  aside {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(11rem, auto));
    gap: 1.5rem;
    justify-content: start;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    border-top: 1px solid var(--mono-800);
    border-bottom: 1px solid var(--mono-800);
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;

    @include breakpoint(large, min) {
      & {
        justify-content: space-between;
      }
    }

    section {
      padding: 0;
    }

    h3 {
      font-weight: normal;
      font-size: 0.7rem;
      line-height: 1.15;
      letter-spacing: 0.2ex;
      text-transform: uppercase;
      color: var(--mono-550);
    }

    div {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: 1fr;
      gap: 1.5rem;

      // TODO: Update layout/breakpoints
      @include breakpoint(large, min) {
        & {
          grid-auto-flow: column;
        }
      }
    }

    [src*="openedx"],
    [src*="tutor"] {
      filter: contrast(0) brightness(1.2); // Faux #999
    }

    [src*="openedx"] {
      max-width: 11rem;
      margin: -0.5rem -2.5rem -0.5rem -2.375rem;
    }

    [src*="tutor"] {
      max-width: 7.5rem;
      margin-top: -0.25rem;
    }

    [src*="11ty"] {
      max-width: 4.75rem;
      filter: grayscale(1) brightness(0.605); // Faux #999
      mix-blend-mode: lighten;
      margin: -1rem;
    }

    [src*="asa"] {
      max-width: 7.375rem;
      filter: grayscale(1) brightness(0.84); // Faux #999 (Elevate)
      margin: -2rem -0.4rem -0.5rem;
    }

    // TODO: Update layout
    section:first-child {
      padding-right: 2.25rem;
    }

    section:last-child {

      img {
        height: 1.5rem;
      }
    }
  }

  .copyright {
    a {
      color: var(--secondary-500);

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

// Add visual whitespace character to MFEs/LMS footer
#root {
  .site-footer {
    .copyright {
      a {
        margin-left: 0.6ex;
      }
    }
  }
}
